export const selectedAzmoun = (state={teacherSelect:[],resultServer:[],selectedQues:[],breakList:[],sarBargKind:0,details:{},pages:1}, action) => {
    switch (action.type) {
        case 'R14_SET_SELECTED':
            return {...state,teacherSelect:action.selectedlist}
        case 'R14_RESULT':
            return {...state,resultServer:action.resultServer,selectedQues:[]}
        case 'R14_ADD_QUES':
            let tempList=state.selectedQues;
            
            let arraAdd1=state.resultServer;
            if(state.selectedQues.length>0){
                if(!state.selectedQues.find(fii=>fii.id===action.selectedQues.id)){
                    tempList.push(action.selectedQues)
                    arraAdd1.forEach(ww=>{
                        const index2 = ww.find(item=>item.id==action.selectedQues.id);
                        const index3 = ww.indexOf(index2);
                        if (index3 > -1) { 
                            ww[index3].temp1=true;
                            }
                    })  
                }
            }else{
                tempList.push(action.selectedQues)
                
                arraAdd1.forEach(ww=>{
                    const index2 = ww.find(item=>item.id==action.selectedQues.id);
                    const index3 = ww.indexOf(index2);
                    if (index3 > -1) { 
                        ww[index3].temp1=true;
                        }
                })
                
            }
            return {...state,resultServer:arraAdd1,selectedQues:tempList}

            
        case 'R14_REMOVE_QUES':

            let array1=state.selectedQues;
            let array2=state.resultServer;
            const index = array1.indexOf(action.ques);
            if (index > -1) { 
                array1.splice(index, 1); 
                }

            array2.forEach(ww=>{
                const index2 = ww.find(item=>item.id==action.ques.id);
                const index3 = ww.indexOf(index2);

                if (index3 > -1) { 
                    ww[index3].temp1=false;
                    }
            })

            return {...state,resultServer:array2,selectedQues:array1}
        case 'R14_UPDATE_ORDERLIST':
            return {...state,selectedQues:action.orderedList}
        case 'R14_BREAKLIST':
            return {...state,breakList:action.list}
        case 'R14_SARBARG':
            return {...state,sarBargKind:action.sarBarg}
        case 'R14_ADDDETAILS':
            return {...state,details:action.details}
        case 'R14_PAGES':
            return {...state,pages:action.pages}
        case 'R14_USERPANELPRINT':
            return {...state,selectedQues:action.question,breakList:action.breakList,sarBargKind:action.sarBarg,details:action.details,pages:action.pages}

        default:
            return state;
            
    }
}