import React from 'react';



//import redux moudle
import { useSelector } from 'react-redux';


//component----------------------

import Page1 from '../4component-page1/Page1';
import Page2 from '../9component-page2/Page2';

import Page4 from '../11component-page4/Page4';
import Loading from '../2component-Loading/Loadin';
import Alert from './../3component-alert/Alert';
import Azmoon from '../13component-azmoon/Azmoon';
import MyPejvok from '../14omponent-myPejvok/MyPejvok';
import Item2 from '../14omponent-myPejvok/item2/Item2';
import NotFound from '../NotFound/NotFound';
import ResultAzmoun from '../16Rcomponent-resultAzmoun/ResultAzmoun';
import EndAzmounAlert from '../17component-endAzmounAlert/EndAzmounAlert';
import OtherPrint from '../20component-otherPrint/OtherPrint';
import FirstPage from '../firstPage/FirstPage';
import RegisterPage from '../registerPage/RegisterPage';
import RegisterPageDetails from '../registerPageDetails /RegisterPageDetails';
import SelectedPage from '../selectedPage/SelectedPage';
import NextSelectPage from '../nextSelectPage/NextSelectPage';
import NextSelectPageTwo from '../nextSelectPageTwo/NextSelectPageTwo';
import NextSelectPageThree from '../nextSelectPageThree/NextSelectPageThree';
import BuyAcount from '../21component-page5/BuyAcount';
import EndBuy from '../22component-page6/EndBuy';
import UserPanel from '../userPaneh/UserPanel';
import SplashScreen from '../splashScreen/SplashScreen';


//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function MainPage (){
    



//=====================================================
// rendering pages---------------------------------
//=====================================================
const pageActive=useSelector(state=>state.pages)

const renderingPages=()=>{

            if(pageActive.pageActive===-2){
                return <SplashScreen/>
            }else if(pageActive.pageActive===-1){
                return <FirstPage/>
            }else if(pageActive.pageActive===0){
                return <Page1/>
            }else if(pageActive.pageActive===1){
                return <Page2/>
            }else if(pageActive.pageActive===2){
                return <MyPejvok/>
            }else if(pageActive.pageActive===4){
                return <Page4/>
            }else if(pageActive.pageActive===5){
                return <Azmoon/>
            }else if(pageActive.pageActive===6){
                    return <RegisterPage/>
            }else if(pageActive.pageActive===7){
                return <RegisterPageDetails/>
            }else if(pageActive.pageActive===8){
                return <SelectedPage/>
            }else if(pageActive.pageActive===9){
                return <NextSelectPage/>
            }else if(pageActive.pageActive===10){
                return <NextSelectPageTwo/>
            }else if(pageActive.pageActive===11){
                return <NextSelectPageThree/>
            }else if(pageActive.pageActive===12){
                return <BuyAcount></BuyAcount>
            }else if(pageActive.pageActive===13){
                return <EndBuy></EndBuy>
            }else if(pageActive.pageActive===14){
                return <UserPanel></UserPanel>
            }else if(pageActive.pageActive===false){
                return <Loading/>
            }else{
            return  <NotFound/>
            }




}

//=====================================================
// loading---------------------------------
//=====================================================
const loadinStatus=useSelector(state=>state.loading)
const renderLoading=()=>{
    if(loadinStatus){
        return <Loading/>
    } 
}

//=====================================================
// render Alert function---------------------------------
//=====================================================
const AlertStatue=useSelector(state=>state.alert.status)
const renderAlert=()=>{
    if(AlertStatue){
        return <Alert/>
    } 
}
//=====================================================
// render Alert function---------------------------------
//=====================================================
const printDetails=useSelector(state=>state.printDetails.active)
const renderPrint=()=>{
    if(printDetails){
        return <OtherPrint/>
    } 
}
//=====================================================
// render profile edit form---------------------------------
//=====================================================
const profileEdit=useSelector(state=>state.detailsEditForm.status)
const renderProfileEdit=()=>{
    if(profileEdit){
        return <Item2/>
    } 
}
//=====================================================
// render show Result AfterAzmoun---------------------------------
//=====================================================
const azmoun=useSelector(state=>state.azmoun)
const renderShowResultAfterAzmoun=()=>{
    if(azmoun.showResult){
        return <ResultAzmoun/>
    } 
}
//=====================================================
// render end azmoun alert---------------------------------
//=====================================================
const endAzmounAlert=useSelector(state=>state.endAzmounAlert)
const renderEndAzmounAlert=()=>{
    if(endAzmounAlert.status){
        return <EndAzmounAlert/>
    } 
}

//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <>
            {renderLoading()}
            {renderAlert()}
            {renderProfileEdit()}
            {renderingPages()}
            {renderShowResultAfterAzmoun()}
            {renderEndAzmounAlert()}
            {renderPrint()}
        </>
    );

}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================