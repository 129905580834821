import React from "react";


import style from './splashScreen.module.css'



export default function SplashScreen(){
    return (
        <div className={style.contain} >

        </div>

    );
}