import React from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
import { R4pages } from '../redux/action';
//import Styles
import style from './page1.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
//import Images
import img1 from './../img/teacher.png'
import img2 from './../img/page1BG2.png'
import img3 from './../img/page1BG3.png'


//font awesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome} from "@fortawesome/free-solid-svg-icons"




//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Page1() {
//=====================================================
// go to  my Pejvok---------------------------------
//=====================================================
const dispatch=useDispatch()

//=====================================================
// go to  azmoun bank--------------------------------
//=====================================================

const goToAzmounBankFunc=()=>{
    dispatch(R4pages(1,""))
}
const goToBuyAccountFunc=()=>{
    dispatch(R4pages(12,""))
}

//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.contentContainer}>
                <div className={style.box1content}>
                    <div className={`${style.madeAzmounBtn} animate__animated animate__lightSpeedInRight`}  onClick={goToAzmounBankFunc}>ساخت آزمون</div>
                    <div className={`${style.buyAccountBtn} animate__animated animate__lightSpeedInRight`} onClick={goToBuyAccountFunc}>خرید اشتراک</div>
                </div>
                <div className={style.box2content}>
                    <img src={img1} alt="teacher pejvok" />
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================