import React from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
import { R4pages } from '../redux/action';
//import Styles
import style from './BuyAcount.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
//import Images
import img1 from './../img/teacher.png'
import {toPersianNum} from './../function/toPersian'


//font awesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons"




//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function BuyAcount() {
    const allData=useSelector(state=>state.userDetails.allData)
//=====================================================
// go to  my Pejvok---------------------------------
//=====================================================
const dispatch=useDispatch()
const gotoendBuy=(item)=>{
    dispatch(R4pages(13,item))
}
//=====================================================
// go to  azmoun bank--------------------------------
//=====================================================

const renderOff=(item)=>{
    if(item.discount!==null){
        return  <>
                    <div className={style.offItem}>{toPersianNum(new Intl.NumberFormat('en').format(item.price-((item.price/100)*item.discount)))+" "+"تومان"}</div>
                    <div className={style.offLine}></div>
                    <div className={style.offArrow}>
                        <FontAwesomeIcon  icon={faLongArrowAltRight} className={style.iconn}/>
                    </div>
                </>
    }
}

const renderAccountItem=()=>{
    return allData.PlanTeacher.map(item=>{
        let userPic='https://pejvak-konkur.ir/lib/plan-img/'+item.image+"."+item.ext
        return  <div className={style.acountitemCon} key={item.id} onClick={()=>gotoendBuy(item)}>
                    <div className={style.topAcount}>
                        <div className={style.topRight}>
                            <img className={style.profilePic} src={userPic} alt={item.name}/>
                        </div>
                        <div className={style.topLeft}>
                            <span className={style.span1}>{item.name}</span>
                            <span className={style.span2}>{item.number_question+" "+"آزمون"}</span>
                            <span className={style.span3}>{item.period+" "+"ماه"}</span>
                        </div>
                    </div>
                    <div className={style.bottomAcount}>
                        <span className={style.span4}>
                            {toPersianNum(new Intl.NumberFormat('en').format(item.price))+" "+"تومان"}
                            {renderOff(item)}
                        </span>
                    </div>
                    
                </div>  
    })
}

//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.contentContainer}>
                {/* <div className={style.box1content}>
                    <div className={`${style.madeAzmounBtn} animate__animated animate__lightSpeedInRight`}  onClick={goToAzmounBankFunc}>ساخت آزمون</div>
                    <div className={`${style.buyAccountBtn} animate__animated animate__lightSpeedInRight`}>خرید اشتراک</div>
                </div>
                <div className={style.box2content}>
                    <img src={img1} alt="teacher pejvok" />
                </div> */}
                <div className={style.mainLabel}>نوع اشتراک خود را انتخاب کنید</div>
                <div className={style.accountContain}>
                    {renderAccountItem()}
                </div>
            </div>
            
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================