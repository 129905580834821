import React,{useRef,useEffect} from 'react';
//function
import {phoneNumberValidate} from '../../function/validatePhoneNumber'
//redux
import {useDispatch} from 'react-redux'
import {R2alert,R3loading,R13registerFormActive} from '../../redux/action';
//import Styles
import style from './form1register.module.css'
//axios
import axios from 'axios';








//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Form1() {

//=====================================================
// activePhoneNumberInput------------------------------
//=====================================================
const inputRef=useRef(null)
useEffect(()=>{
    inputRef.current.focus()
},[])

//=====================================================
// next form function---------------------------------
//=====================================================
const dispatch=useDispatch()
const nextForm=async()=>{
    dispatch(R3loading(true))
    if(await phoneNumberValidate(inputRef.current.value)){
        sendToServer(inputRef.current.value)
    }else{
        dispatch(R3loading(false))
        dispatch(R2alert(true,"زبان را لاتین کنید یا شماره تلفن را درست وارد کنید."))
        inputRef.current.value=""
    }
}

const sendToServer=(phonenumber)=>{
    dispatch(R3loading(true))
axios({
    method:'post',
    url:'https://www.pejvak-konkur.ir/teacherrest/register',
    data:{
        tel:phonenumber,
        nawstr:"naw%a_m"
    }
})
.then(function (response) {
        
        if(response.data===100){
            dispatch(R3loading(false))
            dispatch(R13registerFormActive(1,phonenumber))
        }else if(response.data===101){
            dispatch(R3loading(false))
            dispatch(R2alert(true,"با این شماره قبلا در پژواک ثبت نام شده و حساب کاربری وجود دارد"))
        }else{
            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            dispatch(R3loading(false))
        }

      })
.catch((error)=>{
    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
    dispatch(R3loading(false))
})

}

//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div  className={`${style.formContainer} animate__animated animate__fadeInRight`}>
            <h1>ثبت نام</h1>
            <h3>سلام</h3>
            <h3>لطفاً شماره موبایل خود را وارد کنید.</h3>
            <div className={style.form}>
                <input ref={inputRef} type="number" placeholder='شماره همراه'  onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            nextForm()
                        }
                    }}   />     
            </div>
            <button onClick={nextForm}>ثبت نام</button>
            <div className={style.rulesContainer}>
                <p>با ثبت نام در پژواک٬</p>
                <p className={style.rulesLink}>قوانین و مقررات پژواک</p>
                <p>را می پذیرم</p>
            </div>
        </div>
    )
}

//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================