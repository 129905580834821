import React,{useEffect,useRef, useState} from 'react'
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleLeft,faArrowRight} from "@fortawesome/free-solid-svg-icons"
//import Styles
import style from './form3register.module.css'
//redux
import {useDispatch,useSelector} from 'react-redux'
import {R13registerFormActive,R2alert,R3loading,R4pages,R7allData} from '../../redux/action';
//function
import {smsCodeValidate} from '../../function/validatePhoneNumber'
//axios
import axios from 'axios'
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Form2register() {





//=====================================================
// active NAME Input------------------------------
//=====================================================

useEffect(()=>{
    nameRef.current.focus()
},[])


const dispatch=useDispatch()


//====================================================
// name---------------------------------
//=====================================================
const [tempName,setTempName]=useState("")

const nameRef=useRef();

const getNameRefFunc=(e)=>{
    setTempName(e.target.value)
}

//=====================================================
// last name---------------------------------
//=====================================================
const [tempLname,setTempLname]=useState("")

const lnameRef=useRef();

const getLnameRefFunc=(e)=>{
    setTempLname(e.target.value)
}
//=====================================================
// email tell---------------------------------
//=====================================================
const emailRef=useRef();

const [tempEmile,setTempEmail]=useState('')


const getEmailRefFunc=async(e)=>{
    setTempEmail(e.target.value)

}
//=====================================================
// shahrestan---------------------------------
//=====================================================
const [tempCity,setTempCity]=useState('')

const cityRef=useRef();

const getCityRefFunc=(e)=>{
    setTempCity(e.target.value)
}
//=====================================================
// register details---------------------------------
//=====================================================
const userD=useSelector(state=>state.registerForm)
const registerDetails=async()=>{
    dispatch(R3loading(true))

    axios({
        method:'post',
        url:'https://www.pejvak-konkur.ir/teacherrest/editteacher',
        data:{
            tel:userD.phoneNumber,
            nawstr:"naw%a_m",
            email:tempEmile,
            city:tempCity,
            name_f:tempName,
            name_l:tempLname
        }
    })
    .then(function (response) {
        
            if(response.data===100){
                dispatch(R2alert(true,"ثبت نام با موفقیت انجام شد برای خرید طرح و تکمیل بقیه مشخصات اقدام نمایید"))
                // dispatch(R3loading(false))
                // dispatch(R4pages(0))  
                getDataAndGoToPage()
            }else if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"سرور٫ اطلاعات شما بارگزاری نشد لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
    
          })
    .catch((error)=>{
        dispatch(R2alert(true,"33333خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        dispatch(R3loading(false))
    })
}
const getDataAndGoToPage=()=>{

    axios({
        method:'post',
        url:'https://pejvak-konkur.ir/teacherrest/infoteacher',
        data:{
            tel:userD.phoneNumber,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
                if(response.data===101){
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                    
                }else{
                    dispatch(R7allData(1,response.data,userD.phoneNumber))
                    if(response.data.Info!==undefined){
                        dispatch(R4pages(0))
                        dispatch(R3loading(false))
                    }
                    else{
                        getDataAndGoToPage()
                    }
                    
                }
                
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        
    })

}
//=====================================================
// render of component---------------------------------
//=====================================================    
    return(
        <div className={`${style.formContainer} animate__animated animate__fadeInRight`}>
            <h1>تکمیل ثبت نام</h1>
            <h2>اطلاعات خود را وارد کنید</h2>
            <div className={style.form}>
                <h4>نام</h4>
                <input type="text" placeholder='نام'  value={tempName} ref={nameRef} onChange={getNameRefFunc} />
                <h4>نام خانوادگی</h4>
                <input type="text" placeholder='نام خانوادگی'  value={tempLname} ref={lnameRef} onChange={getLnameRefFunc} /> 
                <h4>ایمیل</h4>
                <input type="email" value={tempEmile} placeholder={"example@gmail.com"} ref={emailRef} onChange={getEmailRefFunc} />
                <h4>شهرستان</h4>
                <input type="text" value={tempCity} ref={cityRef} onChange={getCityRefFunc} placeholder='شهرستان'/>      
            </div>
            <button onClick={registerDetails} >ثبت</button>

        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================