import React,{useRef,useEffect,useState} from 'react'

//import Styles
import style from './NextSelectPageThree.module.css'
import styles from './../18component-pdf/pdfRender.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';


import {useSelector} from 'react-redux'

import { toPersianNum } from '../function/toPersian';
import ReactToPrint from 'react-to-print';

import { ComponentToPrint } from '../18component-pdf/PdfRender';
import { R14breakList, R4pages } from '../redux/action';
import { useDispatch } from 'react-redux';
import Markup from 'react-html-markup';



import useDynamicRefs from 'use-dynamic-refs';
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function NextSelectPageThree() {
    const componentRef = useRef();
    const selectedAzmounS=useSelector(state=>state.selectedAzmoun)
    const sumGarde=()=>{
        let sum=0;
        selectedAzmounS.selectedQues.forEach(item=>{
            sum+=parseFloat(item.score)
        })
        return sum;
     }

    const azmoun=useSelector(state=>state.selectedAzmoun.selectedQues)
    const breakList=useSelector(state=>state.selectedAzmoun.breakList)
    const allData=useSelector(state=>state.userDetails)

    const inserttt=(main_string, ins_string, pos)=> {
        if(typeof(pos) == "undefined") {
         pos = 0;
       }
        if(typeof(ins_string) == "undefined") {
         ins_string = '';
       }
        return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
    }
    
    
    let ourSubstring = "src="; 
    // ---------------
    const renderQuestionEditFunc=(item)=>{
        if(item.question.includes("src=")){
            let indexTemp=item.question.indexOf(ourSubstring)
            let tempString=inserttt(item.question,'https://pejvak-konkur.ir',(indexTemp+5));
            return   <Markup htmlString={ tempString } />
        }else{
            return   <Markup htmlString={ item.question } />
        }
        
    }
    let lengg =  azmoun.length;
    const renderQues=()=>{
        let heightSize=0;
        return azmoun.map((item,index)=>{
            
            if(heightSize+breakList[index]>812){
                heightSize+=27;
                if(index==lengg-1){
                   let ttttee=heightSize;
                   heightSize=0;
                    return  <>
                                <div className={styles.footer}>گروه آموزشی پژواک</div>
                                <div style={{height:`${1123-(ttttee+10)}px` , minHeight:`${1123-(ttttee+10)}px`}}></div>
                            </>
                }else{
                    let ttttee2=heightSize;
                    heightSize=0;
                    return  <>
                                <div className={styles.footer}>گروه آموزشی پژواک</div>
                                <div style={{height:`${1123-(ttttee2+10)}px` , minHeight:`${1123-(ttttee2+10)}px`}}></div>
                                <div className={styles.header}>
                        <div className={styles.besmA}>باسمه تعالی</div>
                        <div className={styles.testDetails}>
                            <div className={`${styles.testDetailsItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>سوالات درس :</span>
                                <span className={styles.text}>علوم و فنون ادبی ۳</span>
                            </div>
                            <div className={`${styles.testDetailsItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>رشته :</span>
                                <span className={styles.text}>ادبیات و علوم انسانی</span>
                            </div>
                            <div className={styles.testDetailsItem}>
                                <span className={styles.Label}>مقطع :</span>
                                <span className={styles.text}>دوره دوم متوسطه پایه دوازدهم</span>
                            </div>
                        </div>
                        <div className={styles.testTimes}>
                            <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>تاریخ امتحان :</span>
                                <span className={styles.text}>۱۴۰۱/۰۳/۱۹</span>
                            </div>
                            <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>ساعت شروع :</span>
                                <span className={styles.text}>۰۸:۰۰ am</span>
                            </div>
                            <div className={styles.testTimesItem}>
                                <span className={styles.Label}>مدت امتحان :</span>
                                <span className={styles.text}> ۹۰ دقیقه</span>
                            </div>
                        </div>
                        <div className={styles.studenDetails}>
                            <div className={styles.studenDetailsItem}>
                                <span className={styles.Label}>نام و نام خانوادگی :</span>
                                <span className={styles.text}>محمد امین یزدان پناه</span>
                            </div>
                            <div className={styles.studenDetailsItem}>
                                <span className={styles.Label}>دانش آموزان روزانه٬ بزرگسال و داوطلبان آزاد سراسر کشور در نوبت خرداد ماه سال ۱۴۰۱</span>
                            </div>
                        </div>
                        <div className={styles.pejvokLabel}>
                            گروه آموزشی پژواک٬معتبر ترین٬ اولین و جامع ترین سامانه آزمون آنلاین
                        </div>
                        <div className={styles.azmounLabel}>
                            <span>ردیف</span>
                            <span>سؤالات</span>
                            <span>نمره</span>
                        </div>
                                </div>
                            </>
                }

                 

            }else{
                heightSize+=breakList[index]
                return  <div className={styles.quesContain}>
                            <div className={styles.numoo}>{toPersianNum(index)}</div>
                            <div className={styles.quesoo}>   
                                {renderQuestionEditFunc(item)}
                            </div>
                            <div className={styles.baromoo}>{toPersianNum(item.score)}</div>
                        </div>
            }

        })
    }






const renderOnkind2=()=>{
    return          <div   style={{ width: "210mm", height: "297mm" ,direction:"rtl",padding:"10mm"}}>

                        <div className={styles.header}>
                            <div className={styles.besmA}>باسمه تعالی</div>
                            <div className={styles.testDetails}>
                                <div className={`${styles.testDetailsItem} ${styles.leftBorder}`}>
                                    <span className={styles.Label}>سوالات درس :</span>
                                    <span className={styles.text}>علوم و فنون ادبی ۳</span>
                                </div>
                                <div className={`${styles.testDetailsItem} ${styles.leftBorder}`}>
                                    <span className={styles.Label}>رشته :</span>
                                    <span className={styles.text}>ادبیات و علوم انسانی</span>
                                </div>
                                <div className={styles.testDetailsItem}>
                                    <span className={styles.Label}>مقطع :</span>
                                    <span className={styles.text}>دوره دوم متوسطه پایه دوازدهم</span>
                                </div>
                            </div>
                            <div className={styles.testTimes}>
                                <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                                    <span className={styles.Label}>تاریخ امتحان :</span>
                                    <span className={styles.text}>۱۴۰۱/۰۳/۱۹</span>
                                </div>
                                <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                                    <span className={styles.Label}>ساعت شروع :</span>
                                    <span className={styles.text}>۰۸:۰۰ am</span>
                                </div>
                                <div className={styles.testTimesItem}>
                                    <span className={styles.Label}>مدت امتحان :</span>
                                    <span className={styles.text}> ۹۰ دقیقه</span>
                                </div>
                            </div>
                            <div className={styles.studenDetails}>
                                <div className={styles.studenDetailsItem}>
                                    <span className={styles.Label}>نام و نام خانوادگی :</span>
                                    <span className={styles.text}>محمد امین یزدان پناه</span>
                                </div>
                                <div className={styles.studenDetailsItem}>
                                    <span className={styles.Label}>دانش آموزان روزانه٬ بزرگسال و داوطلبان آزاد سراسر کشور در نوبت خرداد ماه سال ۱۴۰۱</span>
                                </div>
                            </div>
                            <div className={styles.pejvokLabel}>
                                گروه آموزشی پژواک٬معتبر ترین٬ اولین و جامع ترین سامانه آزمون آنلاین
                            </div>
                            <div className={styles.azmounLabel}>
                                <span>ردیف</span>
                                <span>سؤالات</span>
                                <span>نمره</span>
                            </div>
                        </div>
                        {renderQues()} 
                    </div>
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.contentContainer}>
                <div className={style.orderLabel}>
                    <div className={style.text}>ترتیب سوالات را مشخص کنید</div>
                    {/* <div className={style.nextBtn} onClick={openSample}>ادامه</div> */}
                    {/* <div className={style.itemCon}>
                        <ReactToPrint
                            trigger={() => <button className={style.nextBtn}>ادامه</button>}
                            content={() => componentRef.current}
                        />
                        <div style={{display:"none"}}>
                            <ComponentToPrint ref={componentRef} />
                        </div>
                        
                    </div> */}
                </div>
                <div className={style.orderLabel}>
                    <div className={style.qnumbersContent}>
                        <span className={style.label}>تعداد سوالات انتخاب شده :</span>
                        <span className={style.qnum}>{toPersianNum(selectedAzmounS.selectedQues.length)}</span>
                    </div>
                    <div className={style.gradeSumContent}>
                        <span className={style.label}>بارم مجموع :</span>
                        <span className={style.sum}>{toPersianNum(sumGarde())} نمره</span>
                    </div>
                </div>
                {renderOnkind2()}
            </div>
        </div>
       
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================