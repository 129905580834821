import React from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
import { R4pages } from '../redux/action';
//import Styles
import style from './EndBuy.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
//import Images




//font awesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome} from "@fortawesome/free-solid-svg-icons"




//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function EndBuy() {
//=====================================================
// go to  my Pejvok---------------------------------
//=====================================================
const dispatch=useDispatch()

//=====================================================
// go to  azmoun bank--------------------------------
//=====================================================

const goToAzmounBankFunc=()=>{
    dispatch(R4pages(1,""))
}
const goToBuyAccountFunc=()=>{
    dispatch(R4pages(12,""))
}

//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.contentContainer}>

            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================