import React from 'react'
//redux
//redux
import {useDispatch, useSelector} from 'react-redux'
import {R2alert, R4backPage } from './../redux/action';
//import Styles
import style from './pageKey.module.css'
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome,faArrowLeft} from "@fortawesome/free-solid-svg-icons"






//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function PageKey() {
//=====================================================
// go to  my Pejvok---------------------------------
//=====================================================
const blockNumber=useSelector(state=>state.pages)
const dispatch=useDispatch()

//=====================================================
// back---------------------------------
//=====================================================
const backFunc=()=>{
    if(blockNumber.pageActive!==blockNumber.nowBlocking){
        dispatch(R4backPage())
    }else{
        dispatch(R2alert(true,"شما دیگر قادر به بازگشت به صحفه سوالات نمی باشید"))
    }
    
}
//window.location = "example.zendesk.com/hc/en-us/articles/123456789-Privacy-Policies"
const gotoPejvok=()=>{
 if(blockNumber.pageActive<8 || blockNumber.pageActive>10) {
    window.location = "http://pejvak-konkur.ir/"
 }
 else{
    dispatch(R2alert(true,"شما در قسمت ساخت آزمون قرار دارید و بازگشت به صفحه ی اصلی امکان پذیز نمی باشد"))
 }  
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.pageKeyContainer}>
            <div className={style.homeBack} title="عقب">
                <div className={style.clickAble} onClick={backFunc}></div>
                <FontAwesomeIcon  icon={faArrowLeft} className={style.homeIcon} />
            </div>
            <div className={style.homeBack} title="خانه">
            <div className={style.clickAble} onClick={gotoPejvok}></div>
                <FontAwesomeIcon  icon={faHome} className={style.homeIcon} />
            </div>
        </div>
    )
 }
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================