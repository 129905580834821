import React, { useState } from 'react';
import style from './box7.module.css'
//redux
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import { R5setColor,R5setAnswer,R5activeQuestion } from '../../redux/action'

import Markup from 'react-html-markup';

//function
import {toPersianNum} from './../../function/toPersian'



//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================

//=====================================================
export default function Box7() {

    const inserttt=(main_string, ins_string, pos)=> {
        if(typeof(pos) == "undefined") {
         pos = 0;
       }
        if(typeof(ins_string) == "undefined") {
         ins_string = '';
       }
        return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
         }
    //=====================================================
    // get hole azmoun details-----------------------------
    //=====================================================
    const holeAzmoun=useSelector(state=>state.azmoun)
    let ourSubstring = "src=";     
    const renderQuestionEditFunc=()=>{
        if(foundQuestion.test.includes("src=")){
            let indexTemp=foundQuestion.test.indexOf(ourSubstring)
            let tempString=inserttt(foundQuestion.test,'https://pejvak-konkur.ir',(indexTemp+5));
            return   <Markup htmlString={ tempString } />
        }else{
            return   <Markup htmlString={ foundQuestion.test } />
        }
        
    }
    const renderAnswerEditFunc=()=>{
        if(typeof foundQuestion.answer==='string'){
            if(foundQuestion.answer.includes("src=")){
                let indexTemp=foundQuestion.answer.indexOf(ourSubstring)
                let tempString=inserttt(foundQuestion.answer,'https://pejvak-konkur.ir',(indexTemp+5));
                return   <Markup htmlString={ tempString } />
            }else{
                return   <Markup htmlString={ foundQuestion.answer } />
            }
        }else{
            return foundQuestion.answer
        }
    }
    
    const renderAnswer1EditFunc=()=>{
        if(typeof foundQuestion.answer1==='string'){
            if(foundQuestion.answer1.includes("src=")){
                let indexTemp=foundQuestion.answer1.indexOf(ourSubstring)
                let tempString=inserttt(foundQuestion.answer1,'https://pejvak-konkur.ir',(indexTemp+5));
                return   <Markup htmlString={ tempString } />
            }else{
                return   <Markup htmlString={ foundQuestion.answer1 } />
            }
        }else{
            return foundQuestion.answer1
        }

    }
    const renderAnswer2EditFunc=()=>{
        if(typeof foundQuestion.answer2==='string'){
            if(foundQuestion.answer2.includes("src=")){
                let indexTemp=foundQuestion.answer2.indexOf(ourSubstring)
                let tempString=inserttt(foundQuestion.answer2,'https://pejvak-konkur.ir',(indexTemp+5));
                return   <Markup htmlString={ tempString } />
            }else{
                return   <Markup htmlString={ foundQuestion.answer2 } />
            }
        }else{
            return foundQuestion.answer2
        }

    }
    const renderAnswer3EditFunc=()=>{
        if(typeof foundQuestion.answer3==='string'){
            if(foundQuestion.answer3.includes("src=")){
                let indexTemp=foundQuestion.answer3.indexOf(ourSubstring)
                let tempString=inserttt(foundQuestion.answer3,'https://pejvak-konkur.ir',(indexTemp+5));
                return   <Markup htmlString={ tempString } />
            }else{
                return   <Markup htmlString={ foundQuestion.answer3 } />
            }
        }else{
            return foundQuestion.answer3
        }

    }

    //====================================================
    // get hole azmoun details-----------------------------
    //=====================================================
    const foundQuestion=holeAzmoun.questionList.find(item=>item.number===holeAzmoun.activeQuestion)
    const renderQuestion=()=>{
        let tempBackgroundColor="#cfd8dc"
        if(foundQuestion.colorStatus===0){
            tempBackgroundColor="#cfd8dc"
        }
        else if(foundQuestion.colorStatus===1){
            tempBackgroundColor="#ffeb3b"
        }
        else if(foundQuestion.colorStatus===2){
            tempBackgroundColor="#e53935"
        }
        else{
            tempBackgroundColor="#8bc34a"
        }
        return  <div className={style.question } style={{backgroundColor:`${tempBackgroundColor}`}}>
                    <div className={style.statusBtn}>
                        <div className={`${style.byt} ${style.yellow}`} onClick={()=>doColorToQues(1)}></div>
                        <div className={`${style.byt} ${style.red}`} onClick={()=>doColorToQues(2)}></div>
                        <div className={`${style.byt} ${style.green}`} onClick={()=>doColorToQues(3)}></div>
                    </div>
                    <div className={style.number}>
                        <div>سوال</div>
                        <div>{toPersianNum(foundQuestion.number)}</div>
                    </div>
                    <div className={style.text}>
                        {renderQuestionEditFunc()}
                    </div>
                    <div className={style.answer} style={{backgroundColor:`${foundQuestion.javab===1?"#80deea":"white"}`}} onClick={()=>setAnswerFunc(1)}>
                        <div className={style.g1} >
                            <span>گزینه</span>
                            <span>۱</span>
                            <span>:</span>
                        </div>
                        <div className={style.g2}>
                            {renderAnswerEditFunc()}
                        </div>
                    </div>
                    <div className={style.answer} style={{backgroundColor:`${foundQuestion.javab===2?"#80deea":"white"}`}} onClick={()=>setAnswerFunc(2)}>
                        <div className={style.g1}>
                            <span>گزینه</span>
                            <span>۲</span>
                            <span>:</span>
                        </div>
                        <div className={style.g2}>
                            {renderAnswer1EditFunc()}
                        </div>
                    </div>
                    <div className={style.answer} style={{backgroundColor:`${foundQuestion.javab===3?"#80deea":"white"}`}} onClick={()=>setAnswerFunc(3)}>
                        <div className={style.g1}>
                            <span>گزینه</span>
                            <span>۳</span>
                            <span>:</span>
                        </div>
                        <div className={style.g2}>
                            {renderAnswer2EditFunc()}
                        </div>
                    </div>
                    <div className={style.answer} style={{backgroundColor:`${foundQuestion.javab===4?"#80deea":"white"}`}} onClick={()=>setAnswerFunc(4)}>
                        <div className={style.g1}>
                            <span>گزینه</span>
                            <span>۴</span>
                            <span>:</span>
                        </div>
                        <div className={style.g2}>
                            {renderAnswer3EditFunc()}
                        </div>
                    </div>
                </div>
    }
    //=====================================================
    // add color-------------------------------------------
    //=====================================================
    const [renserState,setRenderState]=useState(false)
    const dispatch=useDispatch()
    const doColorToQues=(ii)=>{
        setRenderState(!renserState)
        dispatch(R5setColor(foundQuestion.number-1,ii))
        dispatch(R5activeQuestion(foundQuestion.number))
    }
    //=====================================================
    // set color-------------------------------------------
    //=====================================================
    
    const setAnswerFunc=(ii)=>{
        dispatch(R5setAnswer(foundQuestion.number-1,ii))
        setRenderState(!renserState)
        dispatch(R5activeQuestion(foundQuestion.number))
    }

    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            renderQuestion()
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================