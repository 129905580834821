import React, { useEffect, useRef, useState } from 'react'

//import redux moudle
import {useDispatch,useSelector } from 'react-redux';
import {R6deActive,R3loading,R2alert,R7allData,R4pages,R6active} from '../../redux/action';
//import Styles
import style from './item2.module.css'

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserAlt,faPlusSquare,faWindowClose} from "@fortawesome/free-solid-svg-icons"
import SelectList from '../../function/selectList/SelectList'

//data Picker
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

//validate
import {checkMoadelvali,phoneNumberValidate,emailValidate} from "./../../function/validatePhoneNumber"

//axios 
import axios from 'axios';

//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Item2() {
//=====================================================
// edited Data---------------------------------
//=====================================================


//=====================================================
// get data---------------------------------
//=====================================================
const allData=useSelector(state=>state.userDetails)




//=====================================================
// get img and upload---------------------------------
//=====================================================
const [okImg,setOkImg]=useState(allData.allData.Info.image===null?false:true)
const [nochange,setNochange]=useState()
const [imgDate,setImgData]=useState(allData.allData.Info.image===null?false:'https://pejvak-konkur.ir/lib/teacher-img/'+allData.allData.Info.image+"."+allData.allData.Info.ext)

const imgUploadHandler=(e)=>{
    console.log(e)
    const reader=new FileReader();
    reader.onload=()=>{
        if(reader.readyState===2){
            setOkImg(true)
            setImgData(reader.result)

        }
    }
    reader.readAsDataURL(e.target.files[0])
    setNochange(e.target.files[0]);

}

const whichShow=()=>{
    if(okImg){
        return <img className={style.profilePic} src={imgDate} alt="پروفایل"/>
    }
    else{
        return <div className={style.noPic}>
                    <FontAwesomeIcon  icon={faUserAlt} size="1x" /> 
                </div>
    }
}
//=====================================================
//school---------------------------------
//=====================================================
const [tempSchool,setTempSchool]=useState(()=>{
    if(allData.allData.Info.school!==null){
        return allData.allData.Info.school
    }else{
        return ""
    }
})

const schoolRef=useRef();

const getSchoolRefFunc=(e)=>{
    setTempSchool(e.target.value)
}


//=====================================================
// address---------------------------------
//=====================================================
const [tempAddress,setTempAddress]=useState(()=>{
    if(allData.allData.Info.addresss!==null){
        return allData.allData.Info.addresss
    }else{
        return ""
    }
})

const addressRef=useRef();

const getAddressRefFunc=(e)=>{
    setTempAddress(e.target.value)
}




//=====================================================
// shahrestan---------------------------------
//=====================================================
const [tempCity,setTempCit]=useState(()=>{
    if(allData.allData.Info.city!==null){
        return allData.allData.Info.city
    }else{
        return ""
    }
})

const cityRef=useRef();

const getCityRefFunc=(e)=>{
    setTempCit(e.target.value)
}


//=====================================================
// email tell---------------------------------
//=====================================================
const emailRef=useRef();
const emailCheckRef=useRef()
const [tempEmile,setTempEmail]=useState(()=>{
    if(allData.allData.Info.email!==null){
        return allData.allData.Info.email
    }else{
        return ""
    }
})
const [emailEdited,setEmailEdited]=useState(()=>{
    if(allData.allData.Info.email!==null){
        return allData.allData.Info.email
    }else{
        return false
    }
})

const getEmailRefFunc=async(e)=>{
    setTempEmail(e.target.value)
    if(await emailValidate(e.target.value)){
        emailCheckRef.current.innerHTML="✅";
        setEmailEdited(emailRef.current.value);
    }
    else{
        emailCheckRef.current.innerHTML="❌";
        setEmailEdited(false);

    }
}
//=====================================================
// closeForm---------------------------------
//=====================================================
const dispatch=useDispatch()
const closeFormFunc=()=>{
    dispatch(R6deActive())
}
//=====================================================
// virayeh ditails func---------------------------------
//=====================================================
const phonenumber=allData.allData.Info.tel;
const [act1,setac1]=useState(false)
const [act2,setac2]=useState(false)
useEffect(()=>{
    endddFunc()
},[act1])
useEffect(()=>{
    endddFunc()
},[act2])
const holeEditefunc=async()=>{
    dispatch(R3loading(true))
    if(nochange!==undefined){
        await uploadImg()
    }
    else{
        setac1(true)
    }
   
    await virayehFunc()
}
const endddFunc=async()=>{
    if(act1&&act2){
        getDataAndGoToPage()
    }
        

}
const getDataAndGoToPage=()=>{

    axios({
        method:'post',
        url:'https://pejvak-konkur.ir/teacherrest/infoteacher',
        data:{
            tel:phonenumber,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
               console.log(response ,"111")
                if(response.data===101){
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                    
                }else{
                    
                    dispatch(R7allData(1,response.data,phonenumber))
                    dispatch(R6deActive())
                    dispatch(R4pages(2))
                    dispatch(R3loading(false))    
                }
                
          })
    .catch((error)=>{
        dispatch(R2alert(true,"1111خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        
    })

}

const uploadImg=async()=>{

    const formData = new FormData();
    formData.append("nawstr", "naw%a_m");
    formData.append("tel", allData.allData.Info.tel);
    formData.append("img", nochange);
    axios({
        method:'post',
        url:'https://www.pejvak-konkur.ir/teacherrest/imgteacher',
        data:formData,
        headers:{
            'Content-Type': 'multipart/form-data'
        }

    })
    .then(function (response) {
        console.log(response ,"2222")
            if(response.data===100){
                setac1(true)
            }else if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"22221خطای سرور٫ عکس شما بارگزاری نشد لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
    
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید22222."))
        dispatch(R3loading(false))
    })

}
const virayehFunc=async()=>{
    console.log({
            tel:allData.allData.Info.tel,
            nawstr:"naw%a_m",
            email:emailEdited===false?"":emailEdited,
            addresss:tempAddress,
            city:tempCity,
            school:tempSchool,
    })
    axios({
        method:'post',
        url:'https://www.pejvak-konkur.ir/teacherrest/editteacher',
        data:{
            tel:allData.allData.Info.tel,
            nawstr:"naw%a_m",
            email:emailEdited===false?"":emailEdited,
            addresss:tempAddress,
            city:tempCity,
            school:tempSchool,
        }
    })
    .then(function (response) {
        console.log(response.data,"3333")
            if(response.data===100){
                
                setac2(true)
            }else if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"33331 سرور٫ اطلاعات شما بارگزاری نشد لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
    
          })
    .catch((error)=>{
        dispatch(R2alert(true,"33333خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        dispatch(R3loading(false))
    })
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
 
            
            <div className={style.container}>
                <div className={style.editForm}>
                    <FontAwesomeIcon className={style.closeBtn}  icon={faWindowClose} size="2x" onClick={closeFormFunc}/> 
                    <div className={style.item1}>
                        {whichShow()}
                        <input type="file" name="image-upload" id="profImgInput" className={style.profImg} accept="image/*" onChange={imgUploadHandler}/>
                        <div className={style.label}>
                            <label htmlFor="profImgInput">
                                <FontAwesomeIcon  icon={faPlusSquare} size="1x" /> 
                            </label>
                        </div>
                    </div>
                    {/* <h2>پایه تحصیلی</h2>
                    <div className={style.item2}>
                        <SelectList List={payehList} sendSelectedItem={getSelectedItem1}/>
                    </div> */}
                    {/* <h2>رشته تحصیلی</h2>
                    <div className={style.item2}>
                        <SelectList List={reshtehList} sendSelectedItem={getSelectedItem2}/>
                    </div> */}
                    {/* <h2>معدل</h2>
                    <div className={style.item2}>
                        <input type="text" ref={moadelRef} value={tempMoadel}  onChange={getMoadelRefFunc} placeholder='مثال: ۱۲/۲۲' />
                        <div className={style.checkValidate} ref={moadelCheckRef}></div>
                    </div> */}
                    {/* <h2>تاریخ تولد</h2>
                    <div className={style.item2}>
                        <DatePicker            
                            value={birthDay}
                            format="YYYY/MM/DD "
                            calendar={persian}
                            locale={persian_fa}
                            calendarPosition="bottom-right"
                            onChange={handleChangeData}  
                        />
                    </div> */}
                    <h2>شهرستان</h2>
                    <div className={style.item2} >
                        <input type="text" value={tempCity} ref={cityRef} onChange={getCityRefFunc}/>
                    </div>
                    <h2>آدرس</h2>
                    <div className={style.item2} >
                        <input type="text" value={tempAddress} ref={addressRef} onChange={getAddressRefFunc}/>
                    </div>
                    <h2>مدرسه</h2>
                    <div className={style.item2} >
                        <input type="text" value={tempSchool} ref={schoolRef} onChange={getSchoolRefFunc}/>
                    </div>
                    {/* <h2>شماره همراه</h2>
                    <div className={style.item2}>
                        <input type="text" value={allData.allData.Info.tel} ref={tellRef} onChange={getTellRefFunc} placeholder="مثال: 09155614269 (لاتین)"/>
                        <div className={style.checkValidate} ref={tellCheckRef}></div>
                    </div> */}
                    <h2>ایمیل</h2>
                    <div className={style.item2}>
                        <input type="email" value={tempEmile} placeholder={"example@gmail.com"} ref={emailRef} onChange={getEmailRefFunc} />
                        <div className={style.checkValidate} ref={emailCheckRef}></div>
                    </div>
                    <div className={style.doneEdite} onClick={holeEditefunc}>ویرایش</div>
                </div>
  
            </div>



    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================