import React from 'react';
//redux

//import Styles
import style from './registerPage.module.css'

//font awesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome} from "@fortawesome/free-solid-svg-icons"
//images
import registerImg from './../img/register.png'

//redux
import {useSelector, useDispatch} from 'react-redux'
import {R4pages} from './../redux/action'
// conmponent
import Form1register from '../1component-Login/Form1register/Form1register'
import Form2register from '../1component-Login/Form2register/Form2register'

//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function RegisterPage() {

    const dispatch=useDispatch()
//=====================================================
//activeForm-------------------------------------------
//=====================================================
const ReduxRegisterForm=useSelector(state=>state.registerForm)

const renderFormActive=()=>{

    if(ReduxRegisterForm.formActive===0){
        return <Form1register/>
    }else if(ReduxRegisterForm.formActive===1){
        return <Form2register/>
    }
    // }else if(ReduxRegisterForm.formActive===3){
    //     return <Form4/>
    // }else if(ReduxRegisterForm.formActive===4){
    //     return <Form5/>
    // }else if(ReduxRegisterForm.formActive===5){
    //     return <Form6/>
    // }
}

const gotologinPageFuc=()=>{
    dispatch(R4pages(-1))
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.mainContainer} >
            <div className={style.header}>
                <div className={style.pageLabel}>ثبت نام دبیران</div>
                <div className={style.homeBack}>
                    <FontAwesomeIcon  icon={faHome} className={style.homeIcon} />
                </div>
            </div>
            <div className={style.contentContainer}>
                {renderFormActive()}
                <div className={style.registerSection}>
                    <img className={style.imgOne} src={registerImg} alt="designImg"/>
                    <div className={style.registerBtn} onClick={gotologinPageFuc}>ورود</div>
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================