import React, { useEffect, useState } from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
//import Styles
import style from './pishAzmoonSelect.module.css'
//component

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons"
import {R11setTempPishazmounDars,R11setTempPishazmounMabhas,R11setTempPishazmounNumber,R10openPishAzmounSelect,R11setEndList,R11deleteTempPishazmounMabhas, R2alert,R11resetTempPish } from '../redux/action'

//dynamic ref
import useDynamicRefs from 'use-dynamic-refs';

//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function PishAzmoonSelect() {


const Rendereee=useSelector(state=>state.openPishAzmounSelect)  
const allData=useSelector(state=>state.userDetails)
const dispatch=useDispatch()
const tempSelect=useSelector(state=>state.tempPishazmoun)
const [rerender,setRerender]=useState(false)
const [showMabhas,setShowMabhas]=useState(false);

useEffect(()=>{

},[Rendereee])
// =====================================================
// render of dars---------------------------------
// =====================================================
// search Dars---------------------------------
const [searchDarsState,setSearchDarsState]=useState('')
const getdarsSearchKey=(e)=>{
    setSearchDarsState(e.target.value)
}
// =====================================================
const [getDarsRef, setDarsRef] =  useDynamicRefs();
const renderDars=()=>{
   
    return     allData.allData.Lesson.map(element => {
        if(searchDarsState.length>0){
            if(element.name.includes(searchDarsState))
            return <div key={element.id} ref={setDarsRef(element.id)}  className={style.bookItem} onClick={()=>selsectedDarsFunc(element)}>{element.name}</div>

        }
        else{
            return <div key={element.id} ref={setDarsRef(element.id)}  className={style.bookItem} onClick={()=>selsectedDarsFunc(element)}>{element.name}</div>
 
        }
    });
}
const selsectedDarsFunc=(element)=>{
 
    if(tempSelect.listEnd.find(rrr=>rrr.dars.id===element.id)===undefined){
        dispatch(R11setTempPishazmounDars(element))
        setRerender(!rerender)
        setShowMabhas(true)
        allData.allData.Lesson.forEach(ttt => {
            const id1 = getDarsRef(ttt.id);
            if(ttt.id===element.id){
                
                id1.current.style.background = '#f9a21c';  
            }
            else{
                id1.current.style.background = ''; 
            }
        });
    }else{
        dispatch(R2alert(true,"شما این درس را انتخاب کرده اید"))
    }


}
// =====================================================
// render of mabhas---------------------------------
// =====================================================

const [getMabhasRef, setMabhasRef] =  useDynamicRefs();
const renderMabahes=()=>{

    if(tempSelect.dars!==undefined){
        return allData.allData.Titeltest.map(item => {
                    if(item.lessonid===tempSelect.dars.id){
                        return <div key={item.titleid} ref={setMabhasRef(item.titleid)} className={style.mabhasItem} onClick={()=>selsectedMabhasFunc(item)}>{item.titlename}</div>
                    }
                    
                });
    } 
}
const selsectedMabhasFunc=(element)=>{
    const id1 = getMabhasRef(element.titleid);
    if(tempSelect.mabahes.find(item=>item.titleid===element.titleid)){
        dispatch(R11deleteTempPishazmounMabhas(element))
        setRerender(!rerender)
        id1.current.style.background = '';  
    }else{
        
        dispatch(R11setTempPishazmounMabhas(element))
        setRerender(!rerender)
        id1.current.style.background = '#f9a21c'; 
    }
 


}

//=====================================================
// set dars--------------------------------
//=====================================================
const setOneDarsFunc=()=>{
    if(tempSelect.dars!==undefined){
        if(tempSelect.mabahes.length>0){
                dispatch(R10openPishAzmounSelect())
                dispatch(R11setEndList({dars:tempSelect.dars,mabahes:tempSelect.mabahes,number:tempSelect.number}))
                dispatch(R11resetTempPish())
        }else{
            dispatch(R2alert(true,"شما هیچ مبحثی از این درس انتخاب نکرده اید"))
        }

    }else{
        dispatch(R2alert(true,"درس راانتخاب کنید"))
    }

}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.backDark}>
            <div className={style.booksCon}>
                <div className={style.labelBook}>انتخاب درس</div>
                <div className={style.search}>
                    <input type="text" onChange={getdarsSearchKey}/>
                </div>
                <div className={style.conCon}>
                    {renderDars()}
                </div>
            </div>
            <div className={style.mabhasCon} style={{display:`${showMabhas?"":"none"}`}}>
                <div className={style.labelmabhas}>انتخاب مباحث</div>

                <div className={style.conCon}>
                    {renderMabahes()}
                </div>
            </div>
            <div className={style.addToPishAzmoon} onClick={setOneDarsFunc}>
                <FontAwesomeIcon  icon={faCheckCircle} size="2x" />
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================