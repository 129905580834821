import React,{useRef,useEffect,useState} from 'react'

//import Styles
import style from './NextSelectPageTwo.module.css'
import styles from './../18component-pdf/pdfRender.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';


import {useSelector} from 'react-redux'

import { toPersianNum } from '../function/toPersian';
import ReactToPrint from 'react-to-print';

import { ComponentToPrint } from '../18component-pdf/PdfRender';
import { R14addDetails, R14breakList, R14pages, R14sarBarg, R3loading, R4pages ,R2alert, R4setBlocking} from '../redux/action';
import { useDispatch } from 'react-redux';
import Markup from 'react-html-markup';
//data Picker
import DatePicker from "react-multi-date-picker"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"


import useDynamicRefs from 'use-dynamic-refs';
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose} from "@fortawesome/free-solid-svg-icons"
import axios from 'axios';
//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function NextSelectPageTwo() {

//=====================================================
// hole State---------------------------------
//=====================================================
    const [whichRender,setWhichRender]=useState(0)
    const selectedAzmounS=useSelector(state=>state.selectedAzmoun)
    const componentRef = useRef();
    const dispatch=useDispatch()
    const azmoun=useSelector(state=>state.selectedAzmoun.selectedQues)
    const azmounDetails=useSelector(state=>state.tempPishazmoun)
    const allData=useSelector(state=>state.userDetails)
    const sarbarg=useSelector(state=>state.selectedAzmoun.sarBargKind)

//=====================================================
// specified which step render-----------------------
//=====================================================
const nextDo=()=>{

    setWhichRender(3)
}
const nextDoTwo=()=>{

    setWhichRender(1)
    dispatch(R4setBlocking(10))
}
//=====================================================
// Show warning-----------------------
//=====================================================
const warningFunc=()=>{
    return <>
            <div className={style.overDarkBak}>
                <div className={style.formContain}>
                    <span className={style.warningTextOne}>دبیر گرامی بعد از پرینت گرفتن آزمون دیگر امکان  جابه جایی ترتیب سوالات یا تغییر سوالات وجود ندارد.</span>
                    <span className={style.warningTextTwo}>( تغییر نوع سربرگ٬ اصلاح اطلاعات سربرگ و چاپ مجدد امکان پذیر است )</span>
                    <div className={style.itemCon}>
                        <div className={style.nextBtn} onClick={nextDoTwo}>ادامه</div>
                        <div className={style.nextBtn} onClick={()=>setWhichRender(0)}>بررسی دوباره</div>
                    </div>
                </div>
            </div>
    </>
}

//=====================================================
// calcute sum of question grade------------------
//=====================================================
    const sumGarde=()=>{
        let sum=0;
        selectedAzmounS.selectedQues.forEach(item=>{
            sum+=parseFloat(item.score)
        })
        return sum;
    }

//=====================================================
// render form for get azmoun details----------------
//=====================================================

const openForm=()=>{
    return <>
            <div className={style.overDarkBak}>
                <div className={style.formContain}>
                    <FontAwesomeIcon  icon={faWindowClose} className={style.closeForm} onClick={()=>setWhichRender(0)}/>
                    <span className={style.mainText}>فرم های زیر را تکمیل کنید</span>
                    <span className={style.label}>رشته تحصیلی</span>
                    <input type="text" value={reshtehState} placeholder='' onChange={getReshteh}/>
                    <span className={style.label}>پایه تحصیلی</span>
                    <input type="text" value={payehState} placeholder='' onChange={getPayeh}/>
                    <span className={style.label}>تاریخ امتحان</span>
                    <DatePicker            
                            value={azmounDay}
                            format="YYYY/MM/DD "
                            calendar={persian}
                            locale={persian_fa}
                            calendarPosition="bottom-right"
                            onChange={handleChangeData}  
                        />
                    <span className={style.label}>زمان شروع</span>
                    <input type="time" value={timeState} placeholder='' onChange={getTime}/>
                    <span className={style.label}>وقت امتحان</span>
                    <input type="text" value={periodState} placeholder='وقت امتحان را به دقیقه وارد کنید' onChange={getPeriod}/>
                    <span className={style.label}>استان</span>
                    <input type="text" value={ostanState} placeholder='' onChange={getOstan}/>
                    <span className={style.label}>شهرستان</span>
                    <input type="text" value={shahrState} placeholder='' onChange={getShahr}/>
                    <span className={style.label}>نام مدرسه</span>
                    <input type="text" value={schoolState} placeholder='' onChange={getSchool}/>
                    <span className={style.label}>نوبت امتحان</span>
                    <input type="text" value={seasonState} placeholder='خرداد٬شهریور ...' onChange={getSeason}/>
                    <span className={style.label}>سال تحصیلی</span>
                    <input type="text" value={yearState} placeholder='' onChange={getYear}/>
                    {checkForm()}

                </div>
            </div>
    
    </>
}
//=====================================================
// function of get details of azmoun in form from teacher
//=====================================================
//get reshteh ----------------------------
const [reshtehState,setReshterState]=useState("")
const getReshteh=(e)=>{
    
    setReshterState(e.target.value)
}
//get payeh ----------------------------
const [payehState,setPayehState]=useState("")
const getPayeh=(e)=>{
    
    setPayehState(e.target.value)
}
// get day---------------------------------
const [azmounDay,setAzmounDay]=useState(null)
const handleChangeData=(value)=>{

    setAzmounDay(value)
}
//get time ----------------------------
const [timeState,setTimeState]=useState("")
const getTime=(e)=>{
    
    setTimeState(e.target.value)
}
//get period ----------------------------
const [periodState,setPeriodState]=useState("")
const getPeriod=(e)=>{
    
    setPeriodState(e.target.value)
}
//get Ostan ----------------------------
const [ostanState,setOstanState]=useState("")
const getOstan=(e)=>{
    
    setOstanState(e.target.value)
}
//get shahr ----------------------------
const [shahrState,setShahrState]=useState("")
const getShahr=(e)=>{
    
    setShahrState(e.target.value)
}
//get School ----------------------------
const [schoolState,setSchoolState]=useState("")
const getSchool=(e)=>{
    
    setSchoolState(e.target.value)
}
//get Season ----------------------------
const [seasonState,setSeasonState]=useState("")
const getSeason=(e)=>{
    
    setSeasonState(e.target.value)
}
//get Year ----------------------------
const [yearState,setYearState]=useState("")
const getYear=(e)=>{
    
    setYearState(e.target.value)
}
//=====================================================
// chech all details inputed in true way and then return btn for next step
//=====================================================
const checkForm=()=>{
    
    if(reshtehState.length===0){
        return <span className={style.AlertForm}>رشته را وارد نکرده اید</span> 
    }else if(payehState.length===0){
        return <span className={style.AlertForm}>پایه را وارد نکرده اید</span> 
    }else if(azmounDay===null){
        return <span className={style.AlertForm}>تاریخ را وارد نکرده اید</span>  
    }else if(timeState.length===0){
        return <span className={style.AlertForm}>زمان را وارد نکرده اید</span> 
    }else if(periodState.length===0){
        return <span className={style.AlertForm}>وقت ازمون را وارد نکرده اید</span>  
    }else if(ostanState.length===0){
        return <span className={style.AlertForm}>استان را وارد نکرده اید</span>  
    }else if(shahrState.length===0){
        return <span className={style.AlertForm}>شهرستان را وارد نکرده اید</span>  
    }else if(schoolState.length===0){
        return <span className={style.AlertForm}>نام مدرسه را وارد نکرده اید</span>  
    }else if(seasonState.length===0){
        return <span className={style.AlertForm}>نوبت امتحانی را وارد نکرده اید</span>  
    }else if(yearState.length===0){
        return <span className={style.AlertForm}>سال تحصیلی را وارد نکرده اید</span>  
    }else{
       return <div className={style.pdfBtn}  onClick={setResultToRedux} >ادامه</div>
    }
}
//=====================================================
// function for send details to redux and set status to show print btn in page
//=====================================================
const setResultToRedux=()=>{
    dispatch(R14addDetails({reshteh:reshtehState,
        payeh:payehState,
        day:azmounDay,
        time:timeState,
        period:periodState,
        ostan:ostanState,
        shahr:shahrState,
        school:schoolState,
        season:seasonState,
        year:yearState}))
    calcuteNumberOfPage()
    setWhichRender(2)
    
}
//=====================================================
// calcute number of pages
//=====================================================
const calcuteNumberOfPage=()=>{
    let tempSum=0;
    let numberOfPage=1;
    selectedAzmounS.breakList.forEach(item=>{
        if(tempSum+item>765){
            tempSum=item;
            numberOfPage++; 
        }else{
            tempSum+=item ;
        }
    })
    dispatch(R14pages(numberOfPage))

}
//=====================================================
// render the print btn in page
//=====================================================
const printBtn=()=>{
    return <>
            <div className={style.overDarkBak}>
                <div className={style.formContain}>
                    <span className={style.mainText}> چاپ و ذخیره آزمون </span>
                    <div className={style.itemCon}>
                        <ReactToPrint
                            trigger={() => <button className={style.nextBtn} >چاپ آزمون</button>}
                            content={() => componentRef.current}
                            onAfterPrint={endendPrint}
                        />
                        <div style={{display:"none"}}>
                            <ComponentToPrint ref={componentRef} />
                        </div>
                        
                    </div>
                </div>

            </div>
    </>
}
//=====================================================
// function that excute after print
//=====================================================
const endendPrint=(e)=>{
    setWhichRender(0)
    setEndAzmounStatus(true)
}
//=====================================================
// render of question and get size---------------------
//=====================================================
const inserttt=(main_string, ins_string, pos)=> {
    if(typeof(pos) == "undefined") {
     pos = 0;
   }
    if(typeof(ins_string) == "undefined") {
     ins_string = '';
   }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
}
let ourSubstring = "src="; 
// ---------------
const renderQuestionEditFunc=(item)=>{
    if(item.question.includes("src=")){
        let indexTemp=item.question.indexOf(ourSubstring)
        let tempString=inserttt(item.question,'https://pejvak-konkur.ir',(indexTemp+5));
        return   <Markup htmlString={ tempString } />
    }else{
        return   <Markup htmlString={ item.question } />
    }
    
}
const [quesHeightRef, setQuesHeightRef] =  useDynamicRefs();

const madeHeightList=()=>{
    let breakPOINTList=[];
    azmoun.forEach(rrr=>{
        const id1 = quesHeightRef(rrr.id); 
        breakPOINTList.push(id1.current.clientHeight)
    })
    dispatch(R14breakList(breakPOINTList))
}
const renderQues=()=>{
    return azmoun.map((item,index)=>{
        
        return  <div className={styles.quesContain} ref={setQuesHeightRef(item.id)}>
                    <div className={styles.numoo}>{toPersianNum(index+1)}</div>
                    <div className={styles.quesoo}>   
                        {renderQuestionEditFunc(item)}
                    </div>
                    <div className={styles.baromoo}>{toPersianNum(item.score)}</div>
                </div>
    })
}
useEffect(()=>{
    madeHeightList()
},[])
const renderOnkind2=()=>{
    return <div  style={{ width: "210mm", height: "297mm" ,direction:"rtl",padding:"10mm"}}>
                    <div  className={styles.content}>
                        {renderQues()} 
                    </div>
            </div>}


//=====================================================
// render of sarbarg-----------------------------------
//=====================================================
const selectSarBarg=(sar)=>{
    dispatch(R14sarBarg(sar))
}
const renderSarBarg=()=>{
    return <>
    <div className={sarbarg===1?style.sarbargActive:style.sarbarg} onClick={()=>selectSarBarg(1)}>
        <div  style={{ width: "210mm",direction:"rtl",padding:"10mm"}}>
            <div className={styles.header}>
                <div className={styles.besmA}>باسمه تعالی</div>
                <div className={styles.testDetails}>
                    <div className={`${styles.testDetailsItem} ${styles.leftBorder}`}>
                        <span className={styles.Label}>سوالات درس :</span>
                        <span className={styles.text}>{azmounDetails.listEnd[0].dars.name}</span>
                    </div>
                    <div className={`${styles.testDetailsItem} ${styles.leftBorder}`}>
                        <span className={styles.Label}>رشته :</span>
                        <span className={styles.text}>{allData.allData.Field.find(itt=>itt.id===azmounDetails.listEnd[0].dars.field_id).name}</span>
                    </div>
                    <div className={styles.testDetailsItem}>
                        <span className={styles.Label}>مقطع :</span>
                        <span className={styles.text}></span>
                    </div>
                </div>
                <div className={styles.testTimes}>
                    <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                        <span className={styles.Label}>تاریخ امتحان :</span>
                        <span className={styles.text}> / / </span>
                    </div>
                    <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                        <span className={styles.Label}>ساعت شروع :</span>
                        <span className={styles.text}>...:...</span>
                    </div>
                    <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                        <span className={styles.Label}>مدت امتحان :</span>
                        <span className={styles.text}> ... دقیقه</span>
                    </div>
                    <div className={styles.testTimesItem}>
                        <span className={styles.Label}>تعداد صفحه :</span>
                        <span className={styles.text}>...</span>
                    </div>
                </div>
                <div className={styles.studenDetails}>
                    <div className={ `${styles.studenDetailsItem} ${styles.studenDetailsItemOne}`}>
                        <span className={styles.Label}>نام و نام خانوادگی :</span>
                        <span className={styles.text}></span>
                    </div>
                    <div className={styles.studenDetailsItemTwo}>
                        <span className={styles.Label}>دانش آموزان روزانه٬ بزرگسال و داوطلبان آزاد سراسر کشور در نوبت ... ماه سال ...</span>
                    </div>
                </div>
                <div className={styles.pejvokLabel}>
                    گروه آموزشی پژواک٬معتبر ترین٬ اولین و جامع ترین سامانه آزمون آنلاین
                </div>
            </div>
        </div>
    </div>
    <div className={sarbarg===2?style.sarbargActive:style.sarbarg} onClick={()=>selectSarBarg(2)}>
        <div  style={{ width: "210mm",direction:"rtl",padding:"10mm"}}>
            <div className={styles.headerTwo}>
                <div className={styles.radifOne}>
                    <div className={`${styles.rightSec} ${styles.brTop} ${styles.brBottom} ${styles.brRight}`}>
                        <div className={`${styles.p1} ${styles.brBottom}`}>
                            <div className={styles.labelll}>نام و نام خانوادگی :</div>
                            <div className={styles.texttt}></div>
                        </div>
                        <div className={`${styles.p1} ${styles.brBottom}`}>
                            <div className={`${styles.p1c1} ${styles.brLeft}`}>
                                <div className={styles.labelll}>نام پدر :</div>
                                <div className={styles.texttt}></div>
                            </div>
                            <div className={styles.p1c2}>
                                <div className={styles.labelll}>شماره صندلی :</div>
                                <div className={styles.texttt}></div>
                            </div>
                        </div>
                        <div className={`${styles.p1} ${styles.brBottom}`}>
                            <div className={`${styles.p1c3} ${styles.brLeft}`}>
                                <div className={styles.labelll}>پایه :</div>
                                <div className={styles.texttt}></div>
                            </div>
                            <div className={styles.p1c3}>
                                <div className={styles.labelll}>شعبه کلاس :</div>
                                <div className={styles.texttt}></div>
                            </div>
                        </div>
                        <div className={styles.p1}>
                            <div className={`${styles.p1c3} ${styles.brLeft}`}>
                                <div className={styles.labelll}>شماره صندلی :</div>
                                <div className={styles.texttt}></div>
                            </div>
                            <div className={styles.p1c3}>
                                <div className={styles.labelll}>رشته :</div>
                                <div className={styles.texttt}></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.centerSec} ${styles.brTop} ${styles.brBottom}`}>
                        <span>بسمه تعالی</span>
                        <span> وزارت آموزش و پرورش</span>
                        <span>اداره کل آموزش و پرورش استان {"..."}</span>
                        <span>مدیریت آموزش و پرورش شهرستان {"..."}</span>
                        <span>نام مدرسه</span>
                    </div>
                    <div className={`${styles.leftSec} ${styles.brTop} ${styles.brBottom} ${styles.brLeft}`}>
                        <div className={styles.p2}>
                            <div className={styles.p2p1}>
                                <div className={`${styles.p2p1c1} ${styles.brBottom} ${styles.brLeft}`}>
                                    <div className={styles.labelll}>نام درس :</div>
                                    <div className={styles.texttt}></div>
                                </div>
                                <div className={`${styles.p2p1c1} ${styles.brBottom} ${styles.brLeft}`}>
                                    <div className={styles.labelll}>تاریخ امتحان :</div>
                                    <div className={styles.texttt}></div>
                                </div>
                            </div>
                            <div className={`${styles.p2p2} ${styles.brBottom}`}>
                                محل مهر آموزشگاه
                            </div>
                        </div>
                        <div className={styles.p3}>
                            <div className={`${styles.p3c1} ${styles.brBottom} ${styles.brLeft}`}>
                                <div className={styles.labelll}>تعداد سوال :</div>
                                <div className={styles.texttt}></div>
                            </div>
                            <div className={`${styles.p3c1} ${styles.brBottom}`}>
                                <div className={styles.labelll}>تعداد صفحه :</div>
                                <div className={styles.texttt}></div>
                            </div>
                        </div>
                        <div className={styles.p3}>
                            <div className={`${styles.p3c1} ${styles.brLeft}`}>
                                <div className={styles.labelll}>زمان شروع :</div>
                                <div className={styles.texttt}></div>
                            </div>
                            <div className={styles.p3c1}>
                                <div className={styles.labelll}>وقت :</div>
                                <div className={styles.texttt}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.radifTwo}>
                    ضمن خیر مقدم به دانش آموزان و داوطلبان عزیز سوالات زیر را به دقت بخوانید و با توکل به خدا و آرامش خاطر پاسخ دهید.
                </div>
                <div className={styles.radifThree}>
                    <div className={styles.tp1}>
                        نمره برگه
                    </div>
                    <div className={styles.tp2}>
                        <div className={styles.tpChild}>باعدد</div>
                        <div className={styles.tpChildTwo}>باحروف</div>
                    </div>
                    <div className={styles.tp3}>
                        <div className={styles.tpChild}></div>
                        <div className={styles.tpChildTwo}></div>
                    </div>
                    <div className={styles.tp4}>
                        <div className={styles.tpChild}>نام و نام خانوادگی مصحح:</div>
                        <div className={styles.tpChildTwo}></div>
                    </div>
                    <div className={styles.tp5}>
                        امضاء
                    </div>
                    <div className={styles.tp6}>
                        نمره تجدید نظر
                    </div>
                    <div className={styles.tp7}>
                        <div className={styles.tpChild}>باعدد</div>
                        <div className={styles.tpChildTwo}>با حروف</div>
                    </div>
                    <div className={styles.tp8}>
                        <div className={styles.tpChild}></div>
                        <div className={styles.tpChildTwo}></div>
                    </div>
                    <div className={styles.tp9}>
                        امضاء
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className={sarbarg===3?style.sarbargActive:style.sarbarg} onClick={()=>selectSarBarg(3)}>
        <div  style={{ width: "210mm",direction:"rtl",padding:"10mm"}}>
            <div className={styles.headerThree}>
                <div className={styles.thHCon}>
                    <div className={styles.part}>
                        <div className={styles.child}>نام و نام خانوادگی :</div>
                        <div className={styles.child}>نام درس :{" "+""}</div>
                        <div className={styles.child}>پایه تحصیلی:{" "+""}</div>
                    </div>
                    <div className={styles.partcenter}>
                        <span>بسمه تعالی</span>
                        <span>آموزش و پرورش شهرستان{" "+""}</span>
                        <span>نام مدرسه</span>
                    </div>
                    <div className={styles.part}>
                        <div className={styles.child}>تاریخ امتحان :{" "+""}</div>
                        <div className={styles.child}>زمان امتحان :{" "+""}</div>
                        <div className={styles.child}>نام دبیر :{" "+""}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
}
//=====================================================
// render of end btn of azmoun-----------------------
//=====================================================
const [endAzmounStatus,setEndAzmounStatus]=useState(false)
const renderEndAzmounBtn=()=>{
    if(endAzmounStatus){
        return<>
                    <div className={style.nextBtn} onClick={nextDoTwo}>چاپ مجدد</div>
                    <div className={style.nextBtn} onClick={lastEndAzmounFunc}>پایان آزمون</div>
        </>
    }
    return <>
                    <div className={style.text}>سربرگ را انتخاب کنید</div>
                    <div className={style.nextBtn} onClick={nextDo}>ادامه</div>
    </>
}
const orderedId=async()=>{
    let rrrr=[]
    azmoun.forEach(item=>{
        rrrr.push(item.id)
    })
    
    return rrrr;
}
const lastEndAzmounFunc=async()=>{
    dispatch(R3loading(true))
    dispatch(R4setBlocking(null))
    let temppp=await orderedId()
    let templisttt=JSON.stringify(temppp)
    let tempHei=JSON.stringify(selectedAzmounS.breakList)

    axios({
        
        method:'post',
        url:'https://pejvak-konkur.ir/teacherrest/historyquestion',
        data:{
            nawstr:"naw%a_m",
            tel:allData.allData.Info.tel,
            field:selectedAzmounS.details.reshteh,
            grade:selectedAzmounS.details.payeh,
            period:selectedAzmounS.details.period,
            day:selectedAzmounS.details.day.format(),
            time:selectedAzmounS.details.time,
            united:selectedAzmounS.details.ostan,
            city:selectedAzmounS.details.shahr,
            school:selectedAzmounS.details.school,
            season:selectedAzmounS.details.season,
            year:selectedAzmounS.details.year,
            type:selectedAzmounS.sarBargKind,
            number:selectedAzmounS.pages,
            question:templisttt,
            lname:azmounDetails.listEnd[0].dars.name,
            breaklist:tempHei,
        }
    })
    .then(function (response) {
            if(response.data){
                dispatch(R4pages(1))
                dispatch(R3loading(false))
                window.location.reload()
            }else{
                dispatch(R2alert(true,"اینترنت خود را چک کنید و دوباره تلاش کنید."))
            }
                
                
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        dispatch(R3loading(false))
    })
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.contentContainer}>
                <div className={style.orderLabel}>

                    {renderEndAzmounBtn()}
                    {whichRender===1?openForm():""}
                    {whichRender===2?printBtn():""}
                    {whichRender===3?warningFunc():""}
                </div>
                <div className={style.orderLabel}>
                    <div className={style.qnumbersContent}>
                        <span className={style.label}>تعداد سوالات انتخاب شده :</span>
                        <span className={style.qnum}>{toPersianNum(selectedAzmounS.selectedQues.length)}</span>
                    </div>
                    <div className={style.gradeSumContent}>
                        <span className={style.label}>بارم مجموع :</span>
                        <span className={style.sum}>{toPersianNum(sumGarde())} نمره</span>
                    </div>
                </div>
                <div className={style.scrollWidthCon}>
                    {renderSarBarg()}
                    {renderOnkind2()}
                </div>

            </div>
        </div>
       
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================