import React, { useState } from "react";
import style from './Drop.module.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect } from "react";

import Markup from 'react-html-markup';

import { useSelector,useDispatch } from "react-redux";
import { R14orderList } from "../redux/action";
import { toPersianNum } from "../function/toPersian";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


const inserttt=(main_string, ins_string, pos)=> {
    if(typeof(pos) == "undefined") {
     pos = 0;
   }
    if(typeof(ins_string) == "undefined") {
     ins_string = '';
   }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
  }
  
  
  let ourSubstring = "src="; 
  // ---------------
  const renderQuestionEditFunc=(item)=>{
    if(item.question.includes("src=")){
        let indexTemp=item.question.indexOf(ourSubstring)
        let tempString=inserttt(item.question,'https://pejvak-konkur.ir',(indexTemp+5));
        return   <Markup htmlString={ tempString } />
    }else{
        return   <Markup htmlString={ item.question } />
    }
    
  }
  // ---------------
  const renderAnswerEditFunc=(item)=>{
    if(item.answer.includes("src=")){
        let indexTemp=item.answer.indexOf(ourSubstring)
        let tempString=inserttt(item.answer,'https://pejvak-konkur.ir',(indexTemp+5));
        return   <Markup htmlString={ tempString } />
    }else{
        return   <Markup htmlString={ item.answer } />
    }
    
  }


function Quote({ quote, index }) {

    const QtypeList=useSelector(state=>state.userDetails.allData)
    const selecteFaslList=useSelector(state=>state.tempPishazmoun.listEnd[0].mabahes)
    const selectedars=useSelector(state=>state.tempPishazmoun.listEnd[0].dars)
  // ---------------
  const getQuessType=(typeId)=>{
    return QtypeList.TypeQuestion.map(element => {
        if(element.id==typeId){
            return element.name
        }
    });
}
// ---------------
const getField=(fieldId)=>{
    return QtypeList.Field.map(element => {
        if(element.id==fieldId){
            return element.name
        }
    });
}
// ---------------
const getPeriod=(periodId)=>{
    if(periodId==1){
        return "دوره اول ابتدایی"
    }else if(periodId==2){
        return "دوره دوم ابتدایی"
    }else if(periodId==3){
        return "دوره اول متوسطه"
    }else{
        return "دوره دوم متوسطه"
    }
}
// ---------------
const getPayeh=(payeh)=>{
    if(payeh==1){
        return "پایه اول"
    }else if(payeh==2){
        return "پایه دوم"
    }else if(payeh==3){
        return "پایه سوم"
    }else if(payeh==4){
        return "پایه چهارم"
    }else if(payeh==5){
        return "پایه پنجم"
    }else if(payeh==6){
        return "پایه شیشم"
    }else if(payeh==7){
        return "پایه هفتم"
    }else if(payeh==8){
        return "پایه هشتم"
    }else if(payeh==9){
        return "پایه نهم"
    }else if(payeh==10){
        return "پایه دهم"
    }else if(payeh==11){
        return "پایه یازدهم"
    }else {
        return "پایه دوازدهم"
    }
}
// ---------------
const getLesson=(lessonId)=>{
    return QtypeList.Lesson.map(element => {
         if(element.id==lessonId){
             return element.name
         }
     });
 }
// ---------------
const getFasle=(part)=>{
   return QtypeList.Titeltest.map(element => {
        if(element.titleid==part){
            return element.titlename
        }
    });
}



  return (
    <Draggable draggableId={quote.id} index={index}>
      {provided => (
        <div  className={style.quessContainerActive} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className={style.quesText}>
                <span className={style.labelM}>سوال :</span>
                {renderQuestionEditFunc(quote)}
            </div>
            <div className={style.answerText}>
                <span className={style.labelM}>پاسخ :</span>
                {renderAnswerEditFunc(quote)}
            </div>
            <div className={style.quessDetails}>
                <div className={style.detailsContain}>
                    {toPersianNum(quote.score)+" "+"نمره"}
                </div>
                <div className={style.detailsContain}>
                    {"منبع"+" : "+quote.source}
                </div>
                <div className={style.detailsContain}>
                    {"سطح سوال"+" : "+toPersianNum(quote.level)}
                </div>
                <div className={style.detailsContain}>
                    {getQuessType(quote.type_question_id)}
                </div>
                <div className={style.detailsContain}>
                    {getField(quote.field_id)}
                </div>
                <div className={style.detailsContain}>
                    {getPeriod(quote.period)}
                </div>
                <div className={style.detailsContain}>
                    {getPayeh(quote.grade)}
                </div>
                <div className={style.detailsContain}>
                    {getLesson(quote.lesson_id)}
                </div>
                <div className={style.detailsContain}>
                    {getFasle(quote.part)}
                </div>
            </div>
        </div>
      )}
    </Draggable>
  );
}

const QuoteList = React.memo(function QuoteList({ quotes }) {
  return quotes.map((quote ,index) => (
    <Quote quote={quote} index={index} key={quote.id} />
  ));
});

function QuoteApp() {
  const dispatch=useDispatch()
  const initial =useSelector(state=>state.selectedAzmoun.selectedQues);
  const [state, setState] = useState({ quotes: initial });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(
      state.quotes,
      result.source.index,
      result.destination.index
    );

    setState({ quotes });
  }
  useEffect(()=>{
    dispatch(R14orderList(state.quotes) );
  },[state])
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <QuoteList quotes={state.quotes} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default QuoteApp;