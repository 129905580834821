import React from 'react';
import { useSelector } from 'react-redux';
//style
import style from './dailyText.module.css';


//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function DailyText() {

    const DailyText=useSelector(state=>state.userDetails.allData.MotivationalMessage)
    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            <div className={style.dailyTextContainer} >
                {DailyText}
            </div>
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================