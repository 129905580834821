import React from 'react';
//redux

//import Styles
import style from './firstPage.module.css'
//redux
import {useSelector, useDispatch} from 'react-redux'
import {R4pages} from './../redux/action'
//font awesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome} from "@fortawesome/free-solid-svg-icons"
//images
import teacherImg from './../img/teacher.png'
// conmponent
import Form1 from '../1component-Login/Form1/Form1'
import Form2 from '../1component-Login/Form2/Form2'
import Form3 from '../1component-Login/Form3/Form3'
import Form4 from '../1component-Login/Form4/Form4'
import Form5 from '../1component-Login/Form5/Form5'
import Form6 from '../1component-Login/Form6/Form6'

//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function FirstPage() {
const dispatch=useDispatch()
const gotoregisterPageFunc=()=>{
    dispatch(R4pages(6))
}
//=====================================================
//activeForm-------------------------------------------
//=====================================================
const ReduxLoginForm=useSelector(state=>state.loginForm)
//function render form active
const renderFormActive=()=>{

    if(ReduxLoginForm.formActive===0){
        return <Form1/>
    }else if(ReduxLoginForm.formActive===1){
        return <Form2/>
    }else if(ReduxLoginForm.formActive===2){
        return <Form3/>
    }else if(ReduxLoginForm.formActive===3){
        return <Form4/>
    }else if(ReduxLoginForm.formActive===4){
        return <Form5/>
    }else if(ReduxLoginForm.formActive===5){
        return <Form6/>
    }
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.mainContainer} >
            <div className={style.header}>
                <div className={style.pageLabel}>ورود دبیران</div>
                <div className={style.homeBack}>
                    <FontAwesomeIcon  icon={faHome} className={style.homeIcon} />
                </div>
            </div>
            <div className={style.contentContainer}>
                {renderFormActive()}
                <div className={style.registerSection}>
                    <img className={style.imgOne} src={teacherImg} alt="designImg"/>
                    <div className={style.registerBtn} onClick={gotoregisterPageFunc}>ثبت نام</div>
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================