export const pages = (state={pageActive:false,pageDetails:{},lastPage:[0],nowBlocking:null}, action) => {
    switch (action.type) {
        case 'R4_PAGEACTIVE_ACTIVE':
            let lastPageTemp=state.lastPage;
            lastPageTemp.push(action.pageActive)
            return {...state,pageActive:action.pageActive,pageDetails:action.pageDetails,lastPage:lastPageTemp}
        case 'R4_BACK_PAGE':
            if(state.lastPage.length>1){
                let popTemp=state.lastPage;
                let yy=popTemp.pop()
                return {...state,pageActive:yy===-1?0:yy,pageDetails:state.pageDetails,lastPage:popTemp}
            }else if(state.lastPage.length===1){
                let popTemp=state.lastPage;
                return {...state,pageActive:popTemp[0]===-1?0:popTemp[0],pageDetails:state.pageDetails,lastPage:popTemp}
            }
        case 'R4_SET_BLOCKING':
            return {...state,nowBlocking:action.blockPageNumber}
        default:
            return state;
            
    }
}
