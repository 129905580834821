import React,{useEffect} from 'react';
import { BrowserRouter , Route , Switch } from 'react-router-dom';
import axios from 'axios'
//redux
import {useDispatch} from 'react-redux'
import {R2alert,R3loading,R4pages,R7allData,R13registerFormActive} from './redux/action';
//component--------------------
import MainPage from './routes/MainPage'
import NotFound from './NotFound/NotFound';

// component Start ------------
function App(props) {
    let temptim=0;
    const userStatus=localStorage.getItem('userStatusTeacherPe');
    const userTell=localStorage.getItem('userTellTeacherPe');
    const dispatch=useDispatch()
    dispatch(R4pages(-2))
    // console.log({userStatus,userTell})
    useEffect(()=>{
        dispatch(R3loading(true))
        if(userStatus==='true'&&userTell!=="undefind"){
            axios({
                method:'post',
                url:'https://pejvak-konkur.ir/teacherrest/infoteacher',
                data:{
                    tel:userTell,
                    nawstr:"naw%a_m"
                }
            })
            .then(function (response) {
                        if(response.data===101){
                            dispatch(R3loading(false))
                            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                            R7allData(false,{},null)
                            dispatch(R4pages(-1))
                        }else{
                            dispatch(R7allData(1,response.data,userTell))
                            if(response.data.Info!==undefined){
                                if((response.data.Info.name_f!==null) && (response.data.Info.name_l!==null)&& (response.data.Info.city!==null)&& (response.data.Info.email!==null)){
                                dispatch(R4pages(0))
                                dispatch(R3loading(false))
                                }else{
                                    dispatch(R13registerFormActive(0,userTell))
                                    dispatch(R3loading(false))
                                    dispatch(R4pages(7))  
                                }
                            }
                            else{
                                getDataAndGoToPage()
                            }
                            
                        }
                        
                  })
            .catch((error)=>{
                dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                
            })
        }else{
            localStorage.setItem('userTellTeacherPe',"undefind")
            localStorage.setItem('userStatusTeacherPe',"false")
            dispatch(R3loading(false))
            dispatch(R4pages(-1))
        }
    })


//=====================================================
// get data and go to Page--------------------
//=====================================================


const getDataAndGoToPage=()=>{
    if(temptim!==2){
        axios({
            method:'post',
            url:'https://pejvak-konkur.ir/teacherrest/infoteacher',
            data:{
                tel:userTell,
                nawstr:"naw%a_m"
            }
        })
        .then(function (response) {
                    if(response.data===101){
                        dispatch(R3loading(false))
                        dispatch(R2alert(true,"222 سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                        
                    }else{
                        dispatch(R7allData(1,response.data,userTell))
                        if(response.data.Info!==undefined){
                            if((response.data.Info.name_f!==null) && (response.data.Info.name_l!==null)&& (response.data.Info.city!==null)&& (response.data.Info.email!==null)){
                                dispatch(R4pages(0))
                                dispatch(R3loading(false))
                                }else{
                                    dispatch(R13registerFormActive(0,userTell))
                                    dispatch(R3loading(false))
                                    dispatch(R4pages(7))  
                                }
                        }
                        else{
                            getDataAndGoToPage()
                            temptim+=1;
                        }
                        
                    }
                    
              })
        .catch((error)=>{
            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            
        })
    }else{
        localStorage.setItem('userTellTeacherPe',"undefind")
        localStorage.setItem('userStatusTeacherPe',"false")
        dispatch(R3loading(false))
        dispatch(R4pages(-1))
    }


}





return (
    
        <BrowserRouter>
            <div className="App">
                <Switch>
                    <Route path="/" exact component={MainPage}/>
                    <Route path="" component={NotFound}/>
                    <Route path="/404" component={NotFound}/>  
                </Switch> 
            </div>
        </BrowserRouter>
  );
}

export default App;
