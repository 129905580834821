import React from 'react';
//redux

//import Styles
import style from './selectedPage.module.css'
//redux
import {useSelector, useDispatch} from 'react-redux'
import {R4pages,R14addQues, R14removeQues} from './../redux/action'
//font awesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes, faWindowClose} from "@fortawesome/free-solid-svg-icons"


// conmponent
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';

//markup
import Markup from 'react-html-markup';



import { toPersianNum } from '../function/toPersian';
import { useState } from 'react';

//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function SelectedPage() {
//=====================================================
// get type Question---------------------------------
//=====================================================
const QtypeList=useSelector(state=>state.userDetails.allData)
const selecteFaslList=useSelector(state=>state.tempPishazmoun.listEnd[0].mabahes)
const selectedars=useSelector(state=>state.tempPishazmoun.listEnd[0].dars)
const selectedAzmounS=useSelector(state=>state.selectedAzmoun)
//=====================================================
// render of component---------------------------------
//=====================================================
const inserttt=(main_string, ins_string, pos)=> {
    if(typeof(pos) == "undefined") {
     pos = 0;
   }
    if(typeof(ins_string) == "undefined") {
     ins_string = '';
   }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
}


let ourSubstring = "src="; 
// ---------------
const renderQuestionEditFunc=(item)=>{
    if(item.question.includes("src=")){
        let indexTemp=item.question.indexOf(ourSubstring)
        let tempString=inserttt(item.question,'https://pejvak-konkur.ir',(indexTemp+5));
        return   <Markup htmlString={ tempString } />
    }else{
        return   <Markup htmlString={ item.question } />
    }
    
}
// ---------------
const renderAnswerEditFunc=(item)=>{
    if(item.answer.includes("src=")){
        let indexTemp=item.answer.indexOf(ourSubstring)
        let tempString=inserttt(item.answer,'https://pejvak-konkur.ir',(indexTemp+5));
        return   <Markup htmlString={ tempString } />
    }else{
        return   <Markup htmlString={ item.answer } />
    }
    
}
// ---------------
const getQuessType=(typeId)=>{
    return QtypeList.TypeQuestion.map(element => {
        if(element.id==typeId){
            return element.name
        }
    });
}
// ---------------
const getField=(fieldId)=>{
    return QtypeList.Field.map(element => {
        if(element.id==fieldId){
            return element.name
        }
    });
}
// ---------------
const getPeriod=(periodId)=>{
    if(periodId==1){
        return "دوره اول ابتدایی"
    }else if(periodId==2){
        return "دوره دوم ابتدایی"
    }else if(periodId==3){
        return "دوره اول متوسطه"
    }else{
        return "دوره دوم متوسطه"
    }
}
// ---------------
const getPayeh=(payeh)=>{
    if(payeh==1){
        return "پایه اول"
    }else if(payeh==2){
        return "پایه دوم"
    }else if(payeh==3){
        return "پایه سوم"
    }else if(payeh==4){
        return "پایه چهارم"
    }else if(payeh==5){
        return "پایه پنجم"
    }else if(payeh==6){
        return "پایه شیشم"
    }else if(payeh==7){
        return "پایه هفتم"
    }else if(payeh==8){
        return "پایه هشتم"
    }else if(payeh==9){
        return "پایه نهم"
    }else if(payeh==10){
        return "پایه دهم"
    }else if(payeh==11){
        return "پایه یازدهم"
    }else {
        return "پایه دوازدهم"
    }
}
// ---------------
const getLesson=(lessonId)=>{
    return QtypeList.Lesson.map(element => {
         if(element.id==lessonId){
             return element.name
         }
     });
 }
// ---------------
const getFasle=(part)=>{
   return QtypeList.Titeltest.map(element => {
        if(element.titleid==part){
            return element.titlename
        }
    });
}
// ---------------
const renderQuestion=()=>{
    
   return selectedAzmounS.resultServer.map((element,inde)=> {
    if(element.length>0){
        if(filterFasl.find(fi=>fi.titleid==element[0].part).status){
            return element.map((quess)=>{
                if(filterKind.find(ki=>ki.id==quess.type_question_id).status||filterKind.find(ki=>ki.id==-2).status){
                    return  <div id={quess.id} className={quess.temp1===true?style.quessContainerActive:style.quessContainer} onClick={()=>addQuesToAzmoun(quess)}>
                                
                                <div className={style.quesText}>
                                    <span className={style.labelM}>سوال :</span>
                                    {renderQuestionEditFunc(quess)}
                                </div>
                                <div className={style.answerText}>
                                    <span className={style.labelM}>پاسخ :</span>
                                    {renderAnswerEditFunc(quess)}
                                </div>
                                <div className={style.quessDetails}>
                                    <div className={style.detailsContain}>
                                        {toPersianNum(quess.score)+" "+"نمره"}
                                    </div>
                                    <div className={style.detailsContain}>
                                        {"منبع"+" : "+quess.source}
                                    </div>
                                    <div className={style.detailsContain}>
                                        {"سطح سوال"+" : "+toPersianNum(quess.level)}
                                    </div>
                                    <div className={style.detailsContain}>
                                        {getQuessType(quess.type_question_id)}
                                    </div>
                                    <div className={style.detailsContain}>
                                        {getField(quess.field_id)}
                                    </div>
                                    <div className={style.detailsContain}>
                                        {getPeriod(quess.period)}
                                    </div>
                                    <div className={style.detailsContain}>
                                        {getPayeh(quess.grade)}
                                    </div>
                                    <div className={style.detailsContain}>
                                        {getLesson(quess.lesson_id)}
                                    </div>
                                    <div className={style.detailsContain}>
                                        {getFasle(quess.part)}
                                    </div>
                                </div>
                            </div>
                }

            })
        }
    }


    })
        
}
const dispatch=useDispatch()
const addQuesToAzmoun=(quessss)=>{
 dispatch(R14addQues({...quessss,temp1:true,temp2:`id-${quessss.id}`}))
}
//=====================================================
// render question Filter---------------------------------
//=====================================================
const [filterKind,setFilterKind]=useState(()=>{
    let list=[{id:-2,name:"همه",status:true}]
    QtypeList.TypeQuestion.forEach(element => {
        list.push({...element,status:false}) 
    });
    return list;
})
const activefilterKind=(item)=>{ 
    if(item.id==-2){
        setFilterKind(()=>{
            let list2=[]
            filterKind.forEach(el=>{
                if(el.id==-2){
                    list2.push({...el,status:true})
                }else{
                    list2.push({...el,status:false})
                }
            })
            return list2;
        }) 
    }else{
        setFilterKind(()=>{
            let list2=[]
            filterKind.forEach(el=>{
                if(el.id==-2){
                    list2.push({...el,status:false})
                }else if(el.id===item.id){
                    list2.push({...el,status:!item.status})
                }else{
                    list2.push(el) 
                }
            })
            return list2;
        })
    }   

}
const renderQuesKindFilter=()=>{
    return<div className={style.filterContainer}>
                {filterKind.map(item=><div className={item.status?style.filterItemActive:style.filterItemDeActive} onClick={()=>activefilterKind(item)}>{item.name}</div>)}
            </div>
}
//=====================================================
// render fasle Filter---------------------------------
//=====================================================
const [filterFasl,setFaslKind]=useState(()=>{
    let list=[]
    selecteFaslList.forEach(element => {
        list.push({...element,status:true}) 
    });
    return list;
})
const activefilterFasl=(item)=>{ 
    setFaslKind(()=>{
            let list2=[]
            filterFasl.forEach(el=>{
                if(el.titleid===item.titleid){
                    list2.push({...el,status:!item.status})
                }else{
                    list2.push(el) 
                }
            })
            return list2;
        })
    }   


const renderQuesFaslFilter=()=>{
    return  <div className={style.filterContainer}>
                {filterFasl.map(item=><div className={item.status?style.filterItemActive:style.filterItemDeActive} onClick={()=>activefilterFasl(item)}>{item.titlename}</div>)}
            </div>
}
const renderFaslLabel=()=>{
    return filterFasl.map(item=><span className={style.mabhasName}>{item.titlename}</span>)

}

// ---------------
// ---------------
const renderSelectedQuestion=()=>{
    
    return selectedAzmounS.selectedQues.map((quess,inde)=> {
            return  <div id={quess.id} className={style.quessContainer2} >
                        <div className={style.deleteSelect} onClick={()=>deleteSelected(quess)}>
                            <FontAwesomeIcon className={style.delBtn}  icon={faTimes} /> 
                        </div>
                        <div className={style.quesText}>
                            <span className={style.labelM}>سوال :</span>
                            {renderQuestionEditFunc(quess)}
                        </div>
                        <div className={style.answerText}>
                            <span className={style.labelM}>پاسخ :</span>
                            {renderAnswerEditFunc(quess)}
                        </div>
                        <div className={style.quessDetails}>
                            <div className={style.detailsContain}>
                                {toPersianNum(quess.score)+" "+"نمره"}
                            </div>
                            <div className={style.detailsContain}>
                                {"منبع"+" : "+quess.source}
                            </div>
                            <div className={style.detailsContain}>
                                {"سطح سوال"+" : "+toPersianNum(quess.level)}
                            </div>
                            <div className={style.detailsContain}>
                                {getQuessType(quess.type_question_id)}
                            </div>
                            <div className={style.detailsContain}>
                                {getField(quess.field_id)}
                            </div>
                            <div className={style.detailsContain}>
                                {getPeriod(quess.period)}
                            </div>
                            <div className={style.detailsContain}>
                                {getPayeh(quess.grade)}
                            </div>
                            <div className={style.detailsContain}>
                                {getLesson(quess.lesson_id)}
                            </div>
                            <div className={style.detailsContain}>
                                {getFasle(quess.part)}
                            </div>
                        </div>
                    </div>
                 }
 
             
 
     )
         
 }

 const sumGarde=()=>{
    let sum=0;
    selectedAzmounS.selectedQues.forEach(item=>{
        sum+=parseFloat(item.score)
    })
    return sum;
 }
 const deleteSelected=(ttt)=>{
    dispatch(R14removeQues(ttt))
 }

 const goToNext=()=>{
    dispatch(R4pages(9,""))
 }
//=====================================================
// show In mobile---------------------------------
//=====================================================
const [showInMobileState,setShowInMobileState]=useState(false)
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
             <div className={style.Container1}>
                <div className={style.selectQuesInMobileBtn} onClick={()=>setShowInMobileState(true)}>سوالات انتخاب شده</div>
                <div className={style.selectQuesInMobileCon} style={{display:`${showInMobileState?'flex':'none'}`}}>
                    <div className={style.topFix}>
                        <FontAwesomeIcon className={style.closeMobileCon}  icon={faWindowClose} onClick={()=>setShowInMobileState(false)}/>
                        <div className={selectedAzmounS.selectedQues.length>0?style.nextBtn:style.nextBtnNone} onClick={goToNext}>ادامه</div>
                        <div className={style.leftHeader}>
                            <div className={style.qnumbersContent}>
                                <span className={style.label}>تعداد سوالات انتخاب شده :</span>
                                <span className={style.qnum}>{toPersianNum(selectedAzmounS.selectedQues.length)}</span>
                            </div>
                            <div className={style.gradeSumContent}>
                                <span className={style.label}>بارم مجموع :</span>
                                <span className={style.sum}>{toPersianNum(sumGarde())} نمره</span>
                            </div>
                        </div>
                    </div>
                    <div className={style.bottomScroll}>
                        {renderSelectedQuestion()}
                    </div>
                </div>
                <div className={style.header}>
                    <div className={style.right}>
                        <CurentTime/>
                        <UserDetails/>
                    </div>
                    <div className={style.center}>
                        <DailyText/>
                        <PageKey/>
                    </div>

                </div>
                <div className={style.contentContainer}>
                    <div className={style.rightCon}>                   
                        <div className={style.rightHeader}>
                            <div className={style.darsContent}>
                                <span className={style.label}>درس :</span>
                                <span className={style.darsName}>{selectedars.name}</span>
                            </div>
                            <div className={style.mabahesContent}>
                                <span className={style.label}>مباحث :</span>
                                {renderFaslLabel()}
                            </div>
                            {renderQuesKindFilter()}
                            {renderQuesFaslFilter()}
                        </div>
                        <div className={style.rightContent}>
                            {renderQuestion()}
                        </div>
                    </div>
                    <div className={style.leftCon}>
                            <div className={selectedAzmounS.selectedQues.length>0?style.nextBtn:style.nextBtnNone} onClick={goToNext}>ادامه</div>
                            <div className={style.leftHeader}>
                                <div className={style.qnumbersContent}>
                                    <span className={style.label}>تعداد سوالات انتخاب شده :</span>
                                    <span className={style.qnum}>{toPersianNum(selectedAzmounS.selectedQues.length)}</span>
                                </div>
                                <div className={style.gradeSumContent}>
                                    <span className={style.label}>بارم مجموع :</span>
                                    <span className={style.sum}>{toPersianNum(sumGarde())} نمره</span>
                                </div>
                            </div>
                            <div className={style.leftContent}>
                                {renderSelectedQuestion()}
                            </div>
                    </div>
                </div>
            </div>

    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================