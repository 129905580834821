import React from 'react';
import style from './myPejvok.module.css'
//redux
import { useDispatch, useSelector } from 'react-redux';
import { R8activeTab } from '../redux/action';
//import Images
import img1 from './../img/page2BG1.png'
//component
import Item1 from './item1/Item1'
import Item3 from './item3/Item3';
import Item4 from './item4/Item4';
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';



//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================

//=====================================================
export default function MyPejvok() {
    //=====================================================
    // render of tab---------------------------------
    //=====================================================
        const tabNumber=useSelector(state=>state.myPejvokTab)

        const renderTabFunc=()=>{
           if( tabNumber.tabNumber===0){
             return <Item1/>
           }else if (tabNumber.tabNumber===1){
               return <Item3/>
           }else if (tabNumber.tabNumber===2){
            return <Item4/>
            }
        }
    //=====================================================
    // active tab---------------------------------
    //=====================================================
        const dispatch=useDispatch()
        const activeTabFunc=(numb)=>{
            dispatch(R8activeTab(numb))
        }

    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            <div className={style.Container} style={{ backgroundImage: `url(${img1})` }}>
                <div className={style.headerContain}>
                    <div className={style.right}>
                        <CurentTime/>
                        {/* <UserDetails/> */}
                    </div>
                    <div className={style.left}>
                        <DailyText/>
                        <PageKey/>
                    </div>
                </div>
                <div className={style.contentContain}>
                    <div className={style.mainContent}>
                        {renderTabFunc()}
                    </div>
                    <div className={style.menuContain}>
                        <div className={tabNumber.tabNumber===0?style.menuBtnA:style.menuBtnD} onClick={()=>activeTabFunc(0)}>اطلاعات من</div>
                        <div className={tabNumber.tabNumber===1?style.menuBtnA:style.menuBtnD} onClick={()=>activeTabFunc(1)}>اعتبار حساب</div>
                        {/* <div className={tabNumber.tabNumber===2?style.menuBtnA:style.menuBtnD} onClick={()=>activeTabFunc(2)}>آزمون ها</div> */}
                        {/* <div className={style.menuBtnD}>ویدیو ها</div>
                        <div className={style.menuBtnD}>جزوه ها</div> */}
                    </div>
                </div>
            </div>
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================