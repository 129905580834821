export const userDetails = (state =
     {LoginStatus:false,kindUser:false,allData:{}}, action) => {

    switch (action.type) {
        case 'R7_CONFIG_DATA':
            localStorage.setItem('userTellTeacherPe',action.phoneNumber)
            localStorage.setItem('userStatusTeacherPe',"true")
            return{LoginStatus:true,kindUser:action.kindUser,allData:action.allData}
        default:
            return state;
    }
}