import React,{useEffect,useRef} from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
import { R4pages,R2alert,R3loading,R7allData, R14usePanelPrint, R11setEndList } from '../redux/action';
//import Styles
import style from './userPanel.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
//import Images
import img1 from './../img/userPanel.png'

import axios from 'axios';

//font awesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserAlt, faWindowClose,faPlusSquare} from "@fortawesome/free-solid-svg-icons"
import moment from 'moment';
import { toPersianNum } from '../function/toPersian';
import { useState } from 'react';

import ReactToPrint from 'react-to-print';

import { ComponentToPrint } from '../18component-pdf/PdfRender';

//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function UserPanel() {
    const componentRef = useRef();
    const allData=useSelector(state=>state.userDetails)
//=====================================================
// go to  my Pejvok---------------------------------
//=====================================================
const dispatch=useDispatch()

//=====================================================
// go to  azmoun bank--------------------------------
//=====================================================

const goToBuyAccountFunc=()=>{
    dispatch(R4pages(12,""))
}
//=====================================================
// user pic---------------------------------
//=====================================================
let userPic;
userPic='https://pejvak-konkur.ir/lib/teacher-img/'+allData.allData.Info.image+"."+allData.allData.Info.ext
const returnUserPicFunc=()=>{
    if(allData.allData.Info.image!==null){
       return <img className={style.profilePic} src={userPic} alt="پروفایل"/>
    }else{
        return  <div className={style.noPic}>
                    <FontAwesomeIcon  icon={faUserAlt} size="2x" /> 
                </div>
    }
}
//=====================================================
// acount details---------------------------------
//=====================================================
const renderAcountStatus=()=>{
    if(allData.allData.Info.status===0){
        return      <div className={style.alert}>
                        شما اشتراکی ندارید.
                        <div className={style.buyAcountBtnn} onClick={goToBuyAccountFunc}>خرید اشتراک</div>
                    </div>
                    
               

    }
    var start = moment().format("YYYY-MM-DD");
    // var start = moment(allData.allData.Info.start_date, "YYYY-MM-DD");
    var end = moment(allData.allData.Info.end_date, "YYYY-MM-DD");

    //Difference in number of days
    let daysOfAccount=parseInt(moment.duration(end.diff(start)).asDays())
    return <>
                    
                    <div className={style.accountItem}>
                        <div className={style.label}>نوع اکانت:</div>
                        <div className={style.data}>{allData.allData.Info.name}</div> 
                    </div>
                    <div className={style.accountItem}>
                        <div className={style.label}>میزان شارژ:</div>
                        <div className={style.data}>{toPersianNum(allData.allData.Info.leftoverquestion) +" "+ "آزمون"}</div> 
                    </div>
                    <div className={style.accountItem}>
                        <div className={style.label}>مدت حساب:</div>
                        <div className={style.data}>{toPersianNum(daysOfAccount)}روز</div> 
                    </div>

    </>
}

//=====================================================
// editRender---------------------------------
//=====================================================
const [editStatusState,setEditStatusState]=useState(false);
const renderEditPan=()=>{
    return <>
            <div className={style.o1one}>
                <div className={style.o2two}>
                    <FontAwesomeIcon  icon={faWindowClose} className={style.oClose} onClick={()=>setEditStatusState(false)}/> 
                    <div className={style.oitem1}>
                        {whichShow()}
                        <input type="file" name="image-upload" id="profImgInput" className={style.profImg} accept="image/*" onChange={imgUploadHandler}/>
                        <div className={style.label}>
                            <label htmlFor="profImgInput">
                                <FontAwesomeIcon className={style.oAddImg}  icon={faPlusSquare} /> 
                            </label>
                        </div>
                    </div>
                    <span className={style.olabel}>نام :</span>
                    <input type="text" value={tempName} onChange={getNameRefFunc}/>
                    <span className={style.olabel}>نام خانوادگی :</span>
                    <input type="text" value={tempLastName} onChange={getLasNameRefFunc} />
                    <span className={style.olabel}>ایمیل :</span>
                    <input type="email" value={tempEmail} onChange={getEmailRefFunc} />
                    <span className={style.olabel}>شهر :</span>
                    <input type="text" value={tempCity} onChange={getCityRefFunc}/>
                    <span className={style.olabel}>آدرس :</span>
                    <input type="text" value={tempAddress} onChange={getAddressRefFunc} />
                    <div className={style.oVirayehBtn} onClick={holeEditefunc}>ویرایش</div>
                </div>
            </div>
    </>
}
// name --------------------
const [tempName,setTempName]=useState(()=>{
    if(allData.allData.Info.name_f!==null){
        return allData.allData.Info.name_f
    }
    return ""
})

const getNameRefFunc=(e)=>{
    setTempName(e.target.value)
}
// LasName --------------------
const [tempLastName,setTempLasName]=useState(()=>{
    if(allData.allData.Info.name_l!==null){
        return allData.allData.Info.name_l
    }
    return ""
})

const getLasNameRefFunc=(e)=>{
    setTempLasName(e.target.value)
}
// email --------------------
const [tempEmail,setTempEmail]=useState(()=>{
    if(allData.allData.Info.email!==null){
        return allData.allData.Info.email
    }
    return ""
})

const getEmailRefFunc=(e)=>{
    setTempEmail(e.target.value)
}
// City --------------------
const [tempCity,setTempCity]=useState(()=>{
    if(allData.allData.Info.city!==null){
        return allData.allData.Info.city
    }
    return ""
})

const getCityRefFunc=(e)=>{
    setTempCity(e.target.value)
}
// address --------------------
const [tempAddress,setTempAddress]=useState(()=>{
    if(allData.allData.Info.addresss!==null){
        return allData.allData.Info.addresss
    }
    return ""
})

const getAddressRefFunc=(e)=>{
    setTempAddress(e.target.value)
}
//=====================================================
// uploadImge---------------------------------
//=====================================================
const [okImg,setOkImg]=useState(allData.allData.Info.image===null?false:true)
const [nochange,setNochange]=useState()
const [imgDate,setImgData]=useState(allData.allData.Info.image===null?false:'https://pejvak-konkur.ir/lib/teacher-img/'+allData.allData.Info.image+"."+allData.allData.Info.ext)

const imgUploadHandler=(e)=>{
    
    const reader=new FileReader();
    reader.onload=()=>{
        if(reader.readyState===2){
            setOkImg(true)
            setImgData(reader.result)

        }
    }
    reader.readAsDataURL(e.target.files[0])
    setNochange(e.target.files[0]);

}

const whichShow=()=>{
    if(okImg){
        return <img className={style.profilePic} src={imgDate} alt="پروفایل"/>
    }
    else{
        return <div className={style.noPic}>
                    <FontAwesomeIcon  icon={faUserAlt} size="1x" /> 
                </div>
    }
}
//=====================================================
// virayeh ditails func---------------------------------
//=====================================================
const phonenumber=allData.allData.Info.tel;
const [act1,setac1]=useState(false)
const [act2,setac2]=useState(false)
useEffect(()=>{
    if(act1&&act2){
        getDataAndGoToPage()
    }
},[act1,act2])

const holeEditefunc=async()=>{
    dispatch(R3loading(true))
    if(nochange!==undefined){
        await uploadImg()
    }
    else{
        setac1(true)
    }
   
    await virayehFunc()
}

const getDataAndGoToPage=()=>{

    axios({
        method:'post',
        url:'https://pejvak-konkur.ir/teacherrest/infoteacher',
        data:{
            tel:phonenumber,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
               
                if(response.data===101){
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                    
                }else{
                    
                    dispatch(R7allData(1,response.data,phonenumber))
                    setEditStatusState(false)
                    dispatch(R4pages(14))
                    dispatch(R3loading(false))    
                }
                
          })
    .catch((error)=>{
        dispatch(R2alert(true,"1111خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        
    })

}

const uploadImg=async()=>{

    const formData = new FormData();
    formData.append("nawstr", "naw%a_m");
    formData.append("tel", allData.allData.Info.tel);
    formData.append("img", nochange);
    axios({
        method:'post',
        url:'https://www.pejvak-konkur.ir/teacherrest/imgteacher',
        data:formData,
        headers:{
            'Content-Type': 'multipart/form-data'
        }

    })
    .then(function (response) {
        
            if(response.data===100){
                setac1(true)
            }else if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"22221خطای سرور٫ عکس شما بارگزاری نشد لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
    
          })
    .catch((error)=>{
        dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید22222."))
        dispatch(R3loading(false))
    })

}
const virayehFunc=async()=>{
    axios({
        method:'post',
        url:'https://www.pejvak-konkur.ir/teacherrest/editteacher',
        data:{
            tel:allData.allData.Info.tel,
            nawstr:"naw%a_m",
            name_f:tempName,
            name_l:tempLastName,
            addresss:tempAddress,
            email:tempEmail,
            city:tempCity,
        }
    })
    .then(function (response) {
            if(response.data===100){
                
                setac2(true)
            }else if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"33331 سرور٫ اطلاعات شما بارگزاری نشد لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }
    
          })
    .catch((error)=>{
        dispatch(R2alert(true,"33333خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        dispatch(R3loading(false))
    })
}


//=====================================================
// return download Azmoun pan-------------------------
//=====================================================
const [renderAzmounState,setRenderAzmounState]=useState(false)
const renderAzmoun=()=>{
    return <>
        <div className={style.o1one}>
            <div className={style.o2two}>
                <FontAwesomeIcon  icon={faWindowClose} className={style.oClose} onClick={()=>setRenderAzmounState(false)}/> 
                {allData.allData.Historyteacher.map(item=>{
                    return <span key={item.id} className={style.azmounLabel} onClick={()=>getQuesFromServer(item)}>{ new Intl.DateTimeFormat('fa-IR',{dateStyle: 'short',timeStyle: 'short'}).format(
                        new Date(moment(item.cratedate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss YYYY/MM/DD')))}</span>
                })}
            </div>
        </div>
    </>
        

}
const getQuesFromServer=(item)=>{
    dispatch(R3loading(true))

    axios({
        method:'post',
        url:'https://pejvak-konkur.ir/teacherrest/historyreturn',
        data:{
            id:item.id,
            nawstr:"naw%a_m"
        }
    })
    .then(function (response) {
               
                if(response.data===101){
                    dispatch(R3loading(false))
                    dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
                    
                }else{
                    dispatch(R14usePanelPrint(
                        response.data.list,
                        JSON.parse(response.data.details.breaklist),
                        response.data.details.type,
                        {
                            reshteh:response.data.details.field,
                            payeh:response.data.details.grade,
                            day:moment(response.data.details.day, 'jYYYY/jMM/jDD'),
                            time:response.data.details.time,
                            period:response.data.details.period,
                            ostan:response.data.details.united,
                            shahr:response.data.details.city,
                            school:response.data.details.school,
                            season:response.data.details.season,
                            year:response.data.details.year
                        },
                        response.data.details.number
                        ))
                    dispatch(R11setEndList({dars:{name:response.data.details.lname}}))
                    dispatch(R3loading(false))
                    setStatusPrintState(true)
                }
                
          })
    .catch((error)=>{
        dispatch(R2alert(true,"1111خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
        
    })
}
//=====================================================
// return just print pan-------------------------
//=====================================================
const [statusPrintState,setStatusPrintState]=useState(false)
const renderPrintPan=()=>{
    return <>
    <div className={style.overDarkBak}>
        <div className={style.formContain}>
            <span className={style.mainText}> چاپ و ذخیره آزمون </span>
            <div className={style.itemCon}>
                <ReactToPrint
                    trigger={() => <button className={style.nextBtn} >چاپ آزمون</button>}
                    content={() => componentRef.current}
                    onAfterPrint={()=>setStatusPrintState(false)}
                />
                <div style={{display:"none"}}>
                    <ComponentToPrint ref={componentRef} />
                </div>
                
            </div>
        </div>

    </div>
</>
}


const goToFirstPage=()=>{
    dispatch(R4pages(0,""))
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            {editStatusState?renderEditPan():""}
            {renderAzmounState?renderAzmoun():""}
            {statusPrintState?renderPrintPan():""}
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.backToFirstPageCon}>
                <div className={style.backToFirstPageBtn}   onClick={goToFirstPage}>ساخت آزمون</div>
            </div>
            <div className={style.contentContainer}>
                <div className={style.box1content}>
                    {returnUserPicFunc()}
                    <div className={style.detailsItem}>
                        <span className={style.label}>نام :</span>
                        <span className={style.text}>{allData.allData.Info.name_f===null?"وارد نشده":allData.allData.Info.name_f}</span>
                    </div>
                    <div className={style.detailsItem}>
                        <span className={style.label}>نام خانوادگی :</span>
                        <span className={style.text}>{allData.allData.Info.name_l===null?"وارد نشده":allData.allData.Info.name_l}</span>
                    </div>
                    <div className={style.detailsItem}>
                        <span className={style.label}>تلفن :</span>
                        <span className={style.text}>{allData.allData.Info.tel}</span>
                    </div>
                    <div className={style.detailsItem}>
                        <span className={style.label}>ایمیل :</span>
                        <span className={style.text}>{allData.allData.Info.email===null?"وارد نشده":allData.allData.Info.email}</span>
                    </div>
                    <div className={style.detailsItem}>
                        <span className={style.label}>شهر :</span>
                        <span className={style.text}>{allData.allData.Info.city===null?"وارد نشده":allData.allData.Info.city}</span>
                    </div>
                    <div className={style.detailsItem}>
                        <span className={style.label}>ادرس :</span>
                        <span className={style.text}>{allData.allData.Info.addresss===null?"وارد نشده":allData.allData.Info.addresss}</span>
                    </div>
                    <div className={style.editDetails} onClick={()=>setEditStatusState(true)}>ویرایش اطلاعات</div>
                    <div className={style.editDetails}  onClick={()=>setRenderAzmounState(true)}>مشاهده تاریخچه آزمون ها و دانلود مجدد</div>
                </div>
                <div className={style.box3content}>
                    {renderAcountStatus()}
                </div>
                <div className={style.box2content}>
                    <img src={img1} alt="teacher pejvok" />
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================