import React from 'react';
import { useSelector } from 'react-redux';
import { toPersianNum } from '../function/toPersian';

import styles from './pdfRender.module.css'

import Markup from 'react-html-markup';


export const ComponentToPrint = React.forwardRef((props, ref) => {
    const azmoun=useSelector(state=>state.selectedAzmoun.selectedQues)
    const breakList=useSelector(state=>state.selectedAzmoun.breakList)
    const details=useSelector(state=>state.selectedAzmoun)
    const azmounDetails=useSelector(state=>state.tempPishazmoun)
    // -------------------------------------------------------
    // -------------------------------------------------------
    // -------------------------------------------------------
    // convert markup to text
    // -------------------------------------------------------
    // -------------------------------------------------------
    // -------------------------------------------------------
    const inserttt=(main_string, ins_string, pos)=> {
        if(typeof(pos) == "undefined") {
         pos = 0;
       }
        if(typeof(ins_string) == "undefined") {
         ins_string = '';
       }
        return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
    }
    let ourSubstring = "src="; 
    // ---------------
    const renderQuestionEditFunc=(item)=>{
        if(item.question.includes("src=")){
            let indexTemp=item.question.indexOf(ourSubstring)
            let tempString=inserttt(item.question,'https://pejvak-konkur.ir',(indexTemp+5));
            return   <Markup htmlString={ tempString } />
        }else{
            return   <Markup htmlString={ item.question } />
        }
        
    }
    // -------------------------------------------------------
    // -------------------------------------------------------
    // -------------------------------------------------------
    // render Kind Header
    // -------------------------------------------------------
    // -------------------------------------------------------
    // -------------------------------------------------------
    const renderSelectedHeader=()=>{
        if(details.sarBargKind===1){
            return  <div className={styles.header}>
                        <div className={styles.besmA}>باسمه تعالی</div>
                        <div className={styles.testDetails}>
                            <div className={`${styles.testDetailsItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>سوالات درس :</span>
                                <span className={styles.text}>{azmounDetails.listEnd[0].dars.name}</span>
                            </div>
                            <div className={`${styles.testDetailsItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>رشته :</span>
                                <span className={styles.text}>{details.details.reshteh}</span>
                            </div>
                            <div className={styles.testDetailsItem}>
                                <span className={styles.Label}>مقطع :</span>
                                <span className={styles.text}>{details.details.payeh}</span>
                            </div>
                        </div>
                        <div className={styles.testTimes}>
                            <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>تاریخ امتحان :</span>
                                <span className={styles.text}>{typeof(details.details.day==='object')?details.details.day.format():details.details.day}</span>
                            </div>
                            <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>ساعت شروع :</span>
                                <span className={styles.text}>{details.details.time}</span>
                            </div>
                            <div className={`${styles.testTimesItem} ${styles.leftBorder}`}>
                                <span className={styles.Label}>مدت امتحان :</span>
                                <span className={styles.text}> {details.details.period+" "} دقیقه</span>
                            </div>
                            <div className={styles.testTimesItem} style={{justifyContent:"flex-start"}}>
                                <span className={styles.Label}>تعداد صفحه :</span>
                                <span className={styles.text}>{toPersianNum(details.pages)+" "} صفحه</span>
                            </div>
                        </div>
                        <div className={styles.studenDetails}>
                            <div className={ `${styles.studenDetailsItem} ${styles.studenDetailsItemOne}`} style={{justifyContent:"flex-start"}}>
                                <span className={styles.Label} style={{marginRight:"3px"}}>نام و نام خانوادگی :</span>
                                <span className={styles.text}></span>
                            </div>
                            <div className={styles.studenDetailsItemTwo}>
                                <span className={styles.Label}>دانش آموزان روزانه٬ بزرگسال و داوطلبان آزاد سراسر کشور در نوبت {" "+details.details.season+" "} ماه سال {" "+details.details.year+" "}</span>
                            </div>
                        </div>
                        <div className={styles.pejvokLabel}>
                            گروه آموزشی پژواک٬معتبر ترین٬ اولین و جامع ترین سامانه آزمون آنلاین
                        </div>
                        <div className={styles.azmounLabel}>
                            <span>ردیف</span>
                            <span>سؤالات</span>
                            <span>نمره</span>
                        </div>
                    </div>
        }
        else if(details.sarBargKind===2){
            return  <div className={styles.headerTwo}>
                        <div className={styles.radifOne}>
                            <div className={`${styles.rightSec} ${styles.brTop} ${styles.brBottom} ${styles.brRight}`}>
                                <div className={`${styles.p1} ${styles.brBottom}`}>
                                    <div className={styles.labelll}>نام و نام خانوادگی :</div>
                                    <div className={styles.texttt}></div>
                                </div>
                                <div className={`${styles.p1} ${styles.brBottom}`}>
                                    <div className={`${styles.p1c1} ${styles.brLeft}`}>
                                        <div className={styles.labelll}>نام پدر :</div>
                                        <div className={styles.texttt}></div>
                                    </div>
                                    <div className={styles.p1c2}>
                                        <div className={styles.labelll}>شماره صندلی :</div>
                                        <div className={styles.texttt}></div>
                                    </div>
                                </div>
                                <div className={`${styles.p1} ${styles.brBottom}`}>
                                    <div className={`${styles.p1c3} ${styles.brLeft}`}>
                                        <div className={styles.labelll}>پایه :</div>
                                        <div className={styles.texttt}>{details.details.payeh}</div>
                                    </div>
                                    <div className={styles.p1c3}>
                                        <div className={styles.labelll}>شعبه کلاس :</div>
                                        <div className={styles.texttt}></div>
                                    </div>
                                </div>
                                <div className={styles.p1}>
                                    <div className={`${styles.p1c3} ${styles.brLeft}`}>
                                        <div className={styles.labelll}>شماره صندلی :</div>
                                        <div className={styles.texttt}></div>
                                    </div>
                                    <div className={styles.p1c3}>
                                        <div className={styles.labelll}>رشته :</div>
                                        <div className={styles.texttt}>{details.details.reshteh}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.centerSec} ${styles.brTop} ${styles.brBottom}`}>
                                <span>بسمه تعالی</span>
                                <span> وزارت آموزش و پرورش</span>
                                <span>اداره کل آموزش و پرورش استان {" "+details.details.ostan}</span>
                                <span>مدیریت آموزش و پرورش شهرستان {" "+details.details.shahr}</span>
                                <span>نام مدرسه{" "+details.details.school}</span>
                            </div>
                            <div className={`${styles.leftSec} ${styles.brTop} ${styles.brBottom} ${styles.brLeft}`}>
                                <div className={styles.p2}>
                                    <div className={styles.p2p1}>
                                        <div className={`${styles.p2p1c1} ${styles.brBottom} ${styles.brLeft}`}>
                                            <div className={styles.labelll}>نام درس :</div>
                                            <div className={styles.texttt}>{azmounDetails.listEnd[0].dars.name}</div>
                                        </div>
                                        <div className={`${styles.p2p1c1} ${styles.brBottom} ${styles.brLeft}`}>
                                            <div className={styles.labelll}>تاریخ امتحان :</div>
                                            <div className={styles.texttt}>{typeof(details.details.day==='object')?details.details.day.format():details.details.day}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.p2p2} ${styles.brBottom}`}>
                                        محل مهر آموزشگاه
                                    </div>
                                </div>
                                <div className={styles.p3}>
                                    <div className={`${styles.p3c1} ${styles.brBottom} ${styles.brLeft}`}>
                                        <div className={styles.labelll}>تعداد سوال :</div>
                                        <div className={styles.texttt}>{toPersianNum(azmoun.length)}</div>
                                    </div>
                                    <div className={`${styles.p3c1} ${styles.brBottom}`}>
                                        <div className={styles.labelll}>تعداد صفحه :</div>
                                        <div className={styles.texttt}>{toPersianNum(details.pages)+" "}</div>
                                    </div>
                                </div>
                                <div className={styles.p3}>
                                    <div className={`${styles.p3c1} ${styles.brLeft}`}>
                                        <div className={styles.labelll}>زمان شروع :</div>
                                        <div className={styles.texttt}>{details.details.time}</div>
                                    </div>
                                    <div className={styles.p3c1}>
                                        <div className={styles.labelll}>وقت :</div>
                                        <div className={styles.texttt}>{toPersianNum(details.details.period)+" "+"دقیقه"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.radifTwo}>
                            ضمن خیر مقدم به دانش آموزان و داوطلبان عزیز سوالات زیر را به دقت بخوانید و با توکل به خدا و آرامش خاطر پاسخ دهید.
                        </div>
                        <div className={styles.radifThree}>
                            <div className={styles.tp1}>
                                نمره برگه
                            </div>
                            <div className={styles.tp2}>
                                <div className={styles.tpChild}>باعدد</div>
                                <div className={styles.tpChildTwo}>باحروف</div>
                            </div>
                            <div className={styles.tp3}>
                                <div className={styles.tpChild}></div>
                                <div className={styles.tpChildTwo}></div>
                            </div>
                            <div className={styles.tp4}>
                                <div className={styles.tpChild}>نام و نام خانوادگی مصحح:</div>
                                <div className={styles.tpChildTwo}></div>
                            </div>
                            <div className={styles.tp5}>
                                امضاء
                            </div>
                            <div className={styles.tp6}>
                                نمره تجدید نظر
                            </div>
                            <div className={styles.tp7}>
                                <div className={styles.tpChild}>باعدد</div>
                                <div className={styles.tpChildTwo}>با حروف</div>
                            </div>
                            <div className={styles.tp8}>
                                <div className={styles.tpChild}></div>
                                <div className={styles.tpChildTwo}></div>
                            </div>
                            <div className={styles.tp9}>
                                امضاء
                            </div>
                        </div>
                    </div>
        }
        else{
            return  <div className={styles.headerThree}>
                        <div className={styles.thHCon}>
                            <div className={styles.part}>
                                <div className={styles.child}>نام و نام خانوادگی :</div>
                                <div className={styles.child}>نام درس :{" "+azmounDetails.listEnd[0].dars.name}</div>
                                <div className={styles.child}>پایه تحصیلی:{" "+details.details.payeh}</div>
                            </div>
                            <div className={styles.partcenter}>
                                <span>بسمه تعالی</span>
                                <span>آموزش و پرورش شهرستان{" "+details.details.shahr}</span>
                                <span>نام مدرسه {" "+details.details.school}</span>
                            </div>
                            <div className={styles.part}>
                                <div className={styles.child}>تاریخ امتحان :{" "+typeof(details.details.day==='object')?details.details.day.format():details.details.day}</div>
                                <div className={styles.child}>زمان امتحان :{" "+details.details.period+" "+"دقیقه"}</div>
                                <div className={styles.child}>نام دبیر :{" "+""}</div>
                            </div>
                        </div>
                        <div className={styles.azmounLabel}>
                            <span>ردیف</span>
                            <span>سؤالات</span>
                            <span>نمره</span>
                        </div>
                    </div>
        }
    }
    // -------------------------------------------------------
    // -------------------------------------------------------
    // -------------------------------------------------------
    // sort pdf item
    // -------------------------------------------------------
    // -------------------------------------------------------
    // -------------------------------------------------------
    const renderThisQuestion=(item,index)=>{
        return  <div className={styles.quesContainStart}>
                    <div className={styles.numoo}>{toPersianNum(index+1)}</div>
                    <div className={styles.quesoo}>   
                        {renderQuestionEditFunc(item)}
                    </div>
                    <div className={styles.baromoo}>{toPersianNum(item.score)}</div>
                </div>
    }
    const renderQues=()=>{
        let lengg=azmoun.length;
        let heightSize=0;
        let topp=false;
        let niii=0
        return azmoun.map((item,index)=>{
            
            if(heightSize+breakList[index+1]>765){
                let yyy=niii;
                niii=0;
                
                if(index==lengg-1){
                   let ttttee=heightSize;
                   heightSize=0;
                   
                    return  <>
                                <div className={styles.footer}>گروه آموزشی پژواک</div>
                                <div style={{height:`${1122-(ttttee+295+yyy)}px` , minHeight:`${1122-(ttttee+295+yyy)}px`,padding:"0px",margin:"0px"}}></div>
                            </>
                }else{
                    let ttttee2=heightSize;
                    heightSize=breakList[index];
                   
                    return  <>
                    <div className={styles.footer}>گروه آموزشی پژواک</div>
                    <div style={{height:`${1122-(ttttee2+295+yyy)}px` , minHeight:`${1122-(ttttee2+295+yyy)}px`,padding:"0px",margin:"0px"}}></div>
                    {renderSelectedHeader()}
                    {renderThisQuestion(item,index)}
                    </>
                }

                 

            }else{
                if(heightSize==0){
                    topp=true 
                }else{
                    topp=false 
                }
                if(index==lengg-1){
                    niii+=1;
                    heightSize+=breakList[index];
                    return  <>
                                <div className={topp==true?styles.quesContainStart:styles.quesContain}>
                                    <div className={styles.numoo}>{toPersianNum(index+1)}</div>
                                    <div className={styles.quesoo}>   
                                        {renderQuestionEditFunc(item)}
                                    </div>
                                    <div className={styles.baromoo}>{toPersianNum(item.score)}</div>
                                </div>
                                <div className={styles.footer}>گروه آموزشی پژواک</div>
                            </>

                }else{
                    niii+=1;
                    heightSize+=breakList[index];
                    return  <div className={topp==true?styles.quesContainStart:styles.quesContain}>
                                <div className={styles.numoo}>{toPersianNum(index+1)}</div>
                                <div className={styles.quesoo}>   
                                    {renderQuestionEditFunc(item)}
                                </div>
                                <div className={styles.baromoo}>{toPersianNum(item.score)}</div>
                            </div>
                }

            }

        })
    }




    // -------------------------------------------------------
    // -------------------------------------------------------
    // -------------------------------------------------------
    // main render
    // -------------------------------------------------------
    // -------------------------------------------------------
    // -------------------------------------------------------
    return (
      
            <div ref={ref}  style={{ width: "210mm", height: "1122.5px" ,direction:"rtl",paddingTop:"37.7px",paddingLeft:"37.7px",paddingRight:"37.7px"}}>
                {renderSelectedHeader()}
                {renderQues()} 
            </div>
    );
  });