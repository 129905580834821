import React,{useRef} from 'react'

//import Styles
import style from './NextSelectPage.module.css'

//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';

import Drop22 from '../DropListComponent/Drop22'

import {useSelector} from 'react-redux'

import { toPersianNum } from '../function/toPersian';


import { R4pages } from '../redux/action';
import { useDispatch } from 'react-redux';

//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function NextSelectPage() {
    
    const selectedAzmounS=useSelector(state=>state.selectedAzmoun)
    const sumGarde=()=>{
        let sum=0;
        selectedAzmounS.selectedQues.forEach(item=>{
            sum+=parseFloat(item.score)
        })
        return sum;
     }
const dispatch=useDispatch()
const openSample=()=>{
    dispatch(R4pages(10,""))
}

//=====================================================
// render of component---------------------------------
//=====================================================






//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.contentContainer}>
                <div className={style.orderLabel}>
                    <div className={style.text}>ترتیب سوالات را مشخص کنید</div>
                    <div className={style.nextBtn} onClick={openSample}>ادامه</div>
                </div>
                <div className={style.orderLabel}>
                    <div className={style.qnumbersContent}>
                        <span className={style.label}>تعداد سوالات انتخاب شده :</span>
                        <span className={style.qnum}>{toPersianNum(selectedAzmounS.selectedQues.length)}</span>
                    </div>
                    <div className={style.gradeSumContent}>
                        <span className={style.label}>بارم مجموع :</span>
                        <span className={style.sum}>{toPersianNum(sumGarde())} نمره</span>
                    </div>
                </div>
                <Drop22/>
            </div>
        </div>
       
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================