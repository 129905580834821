import React,{ useEffect, useRef, useState } from 'react';
//import redux moudle
import {useSelector } from 'react-redux';
import { R4pages } from '../redux/action';
//moment
import moment from 'moment';
//style
import style from './userDetails.module.css';
//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserAlt} from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from 'react-redux';

import { toPersianNum } from '../function/toPersian';

//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function UserDetails() {
    //=====================================================
    // get data---------------------------------
    //=====================================================
    const allData=useSelector(state=>state.userDetails)

    //=====================================================
    // user pic---------------------------------
    //=====================================================
    let userPic;
    userPic='https://pejvak-konkur.ir/lib/teacher-img/'+allData.allData.Info.image+"."+allData.allData.Info.ext
    const returnUserPicFunc=()=>{
        if(allData.allData.Info.image!==null){
        return <img className={style.profilePic} src={userPic} alt="پروفایل"/>
        }else{
            return <FontAwesomeIcon  icon={faUserAlt} className={style.iconn}/>
        }
    }

    //=====================================================
    // animate ---------------------------------
    //=====================================================
    const [animateStatus,setAnimateStatus]=useState(false)
    const addAnimate=()=>{
        setAnimateStatus(true)
    }
    const removeAnimate=()=>{
        setAnimateStatus(false)
    }
    //=====================================================
    // showPan --------------------------------------------
    //=====================================================
    const [showPan,setShowPan]=useState(false)
    const detailsPanRef=useRef()
    const openPan=()=>{
        if(!showPan){
            detailsPanRef.current.style.display="flex"
            setShowPan(true)
        }
        else{
            detailsPanRef.current.style.display="none"
            setShowPan(false)
        }
    }
    const detailsPanRef2=useRef()
    useEffect(() => {
        function handleClickOutside(event) {
          if (detailsPanRef2.current && !detailsPanRef2.current.contains(event.target)) {
                detailsPanRef.current.style.display="none"
                setShowPan(false)
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [showPan]);
    const holeExitFunc=()=>{
        localStorage.setItem('userTellTeacherPe',"undefind")
        localStorage.setItem('userStatusTeacherPe',"false")
        window.location.reload();
    }
    //=====================================================
    // render Acount Status -------------------------------
    //=====================================================
    const renderAcountStatus=()=>{
        if(allData.allData.Info.status===0){
            return      <div className={style.alert}>
                            شما اشتراکی ندارید.
                            <div className={style.buyAcountBtnn} onClick={goToBuyAccountFunc}>خرید اشتراک</div>
                        </div>
                        
                   

        }
        var start = moment().format("YYYY-MM-DD");
        // var start = moment(allData.allData.Info.start_date, "YYYY-MM-DD");
        var end = moment(allData.allData.Info.end_date, "YYYY-MM-DD");

        //Difference in number of days
        let daysOfAccount=parseInt(moment.duration(end.diff(start)).asDays())
        return <>
                        <div className={style.accountItem}>
                            <div className={style.label}>نوع اکانت:</div>
                            <div className={style.data}>{allData.allData.Info.name}</div> 
                        </div>
                        <div className={style.accountItem}>
                            <div className={style.label}>میزان شارژ:</div>
                            <div className={style.data}>{toPersianNum(allData.allData.Info.leftoverquestion) +" " + "آزمون"}</div> 
                        </div>
                        <div className={style.accountItem}>
                            <div className={style.label}>مدت حساب:</div>
                            <div className={style.data}>{toPersianNum(daysOfAccount)}روز</div> 
                        </div>

        </>
    }

    const dispatch=useDispatch()
    const goToBuyAccountFunc=()=>{
        dispatch(R4pages(12,""))
    }

    const openUserPanel=()=>{
        dispatch(R4pages(14,""))
    }
    //=====================================================
    // render of component---------------------------------
    //=====================================================
        return(
            <div className={style.userDetailsContainer}  ref={detailsPanRef2}>
                <div  className={`${style.userIconCon} ${animateStatus?"animate__animated animate__headShake":''} `} onMouseOver={addAnimate} onMouseOut={removeAnimate} onClick={openPan}>
                    {returnUserPicFunc()}
                </div>
                <div ref={detailsPanRef} className={`${style.DetailsPan} animate__animated animate__bounceInDown`}>
                    <div className={style.detailsItem}>
                        <div className={style.label}>نام:</div>
                        <div className={style.data}>{allData.allData.Info.name_f}</div>
                    </div>
                    <div className={style.detailsItem}>
                        <div className={style.label}>نام خانوادگی:</div>
                        <div className={style.data}>{allData.allData.Info.name_l}</div>
                    </div>
                    <div className={style.detailsItem}>
                        <div className={style.label}>شهر:</div>
                        <div className={style.data}>{allData.allData.Info.city}</div>
                    </div>
                    <div className={style.detailsItem}>
                        <div className={style.label}>ادرس:</div>
                        <div className={style.data}>{allData.allData.Info.addresss}</div>
                    </div>
                   <div className={style.detailsItem}>
                        <div className={style.label}>ایمیل :</div>
                        <div className={style.data}>{allData.allData.Info.email}</div>
                    </div>
                    <div className={style.detailsItem}>
                        <div className={style.label}>تلفن :</div>
                        <div className={style.data}>{allData.allData.Info.tel}</div>
                    </div>
                    <div className={style.accountDetailsCon}>
                        {renderAcountStatus()}
                    </div>
                    <div className={style.showPanelLink} onClick={openUserPanel}>مشاهده پنل کاربری</div>
                    <div className={style.exitBtn} onClick={holeExitFunc}>خروج</div>
                </div>
                {showPan?<div onClick={openPan}  className={style.overBackground}></div>:""}
            </div>
        )
    }
    //=====================================================
    //=====================================================
    //=====================================================
    //component end -------------------------------------
    //=====================================================
    //=====================================================
    //=====================================================