import React from 'react'
//redux
import {useDispatch} from 'react-redux'
//import Styles
import style from './selectedItem.module.css'
//component

//font AwesomeIcon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons"
import { R11delEndList } from '../../redux/action'
import { toPersianNum } from '../../function/toPersian'




//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function SelectedItem(props) {


    const dispatch=useDispatch()
    const deleteFunc=()=>{
        dispatch(R11delEndList(props.details))
    }

    const returnMabhas=()=>{
        return props.details.mabahes.map(item=>{
            return <span key={item.titlename} className={style.mabhasName}>{item.titlename}</span>
        })
        }
            
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.selectedCourse}>
            <div className={style.number}>{toPersianNum(props.number)}</div>
            <div className={style.delBtn} onClick={deleteFunc}>
                <FontAwesomeIcon  icon={faTrashAlt} size="1x" /> 
            </div>
            <span className={style.darsNameee}>{props.details.dars.name}</span>
            {returnMabhas()}
        </div>

    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================