import React from 'react';
//redux

//import Styles
import style from './registerPageDetails.module.css'

//font awesome Icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome} from "@fortawesome/free-solid-svg-icons"
//images
import registerImg from './../img/details.png'

//redux
import {useSelector, useDispatch} from 'react-redux'
import {R4pages} from './../redux/action'

import Form3register from '../1component-Login/Form3register/Form3register'

//=====================================================
//=====================================================
//=====================================================
//component start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function RegisterPageDetails() {



//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.mainContainer} >
            <div className={style.header}>
                <div className={style.pageLabel}>ادامه ثبت نام</div>
                <div className={style.homeBack}>
                    <FontAwesomeIcon  icon={faHome} className={style.homeIcon} />
                </div>
            </div>
            <div className={style.contentContainer}>
                <Form3register/>
                <div className={style.registerSection}>
                    <img className={style.imgOne} src={registerImg} alt="designImg"/>
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================