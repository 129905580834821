export const registerForm = (state={formActive:0,phoneNumber:""}, action) => {
    switch (action.type) {
        case 'R13_REGISTERFORM_ACTIVE':

            return {formActive:action.fromNumber,phoneNumber:action.phoneNumber}
        default:
            return state;
            
    }
}

