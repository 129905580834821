import React from 'react'
//redux
import {useDispatch} from 'react-redux'
import { R4pages } from '../redux/action';
//import Styles
import style from './page2.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
//import Images
import img1 from './../img/madeAzmoun.png'







//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Page2() {

//=====================================================
// go azmounak---------------------------------
//=====================================================
const dispatch=useDispatch()
const openPage3Func=()=>{
    dispatch(R4pages(3,""))
}
//=====================================================
// go pishAzmun---------------------------------
//=====================================================
const openPage4Func=()=>{
    dispatch(R4pages(4,""))
}
//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.contentContainer}>
                <div className={style.box1content}>
                    <div className={`${style.madeAzmounBtn} animate__animated animate__lightSpeedInRight`}  onClick={openPage4Func}>انتخابی</div>
                    <div className={`${style.buyAccountBtn} animate__animated animate__lightSpeedInRight`}>پیشفرض</div>
                </div>
                <div className={style.box2content}>
                    <img src={img1} alt="teacher pejvok" />
                </div>
            </div>
        </div>
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================