import React from 'react'
//redux
import {useDispatch, useSelector} from 'react-redux'
//import Styles
import style from './page4.module.css'
//component
import CurentTime from '../5component-CurentTime/CurentTime';
import UserDetails from '../6component-userDetails/UserDetails';
import DailyText from '../7component-dailyText/DailyText';
import PageKey from '../8component-pageKey/PageKey';
import PishAzmoonSelect from '../12component-pishAzmoonSelect/PishAzmoonSelect';
//import Images
import img1 from './../img/selectingTest.png'
import SelectedItem from './selectedItem/SelectedItem';
import { R14setResult,R10openPishAzmounSelect ,R3loading,R2alert,R4pages} from '../redux/action';


// axios
import axios from 'axios';


//=====================================================
//=====================================================
//=====================================================
//component Start -------------------------------------
//=====================================================
//=====================================================
//=====================================================
export default function Page4() {

//=====================================================
// render pishazmoun select---------------------------------
//=====================================================
const statusSelect=useSelector(state=>state.openPishAzmounSelect)
const renderSelect=()=>{
    if(statusSelect){
        return <PishAzmoonSelect/>
    }
}
//=====================================================
// open select btn---------------------------------
//=====================================================
const dispatch=useDispatch()
const openSelectFunc=()=>{
    if(listSelect.listEnd.length==0){
        dispatch(R10openPishAzmounSelect())
    }else{
        dispatch(R2alert(true,"فقط یک درس می توانید انتخاب کنید."))
    }
    
}
//=====================================================
// render selected---------------------------------
//=====================================================
const listSelect=useSelector(state=>state.tempPishazmoun)

let newMabahes=[];
if(listSelect.listEnd.length>0){
    listSelect.listEnd[0].mabahes.forEach(item=>{
        newMabahes.push({id:item.titleid})
    })
}

const renderSelected=()=>{
    return listSelect.listEnd.map((item , index)=>{
                return <SelectedItem key={index} number={index+1} details={item}></SelectedItem>
            })
}
//=====================================================
// render selected---------------------------------
//=====================================================
const allData=useSelector(state=>state.userDetails)
const createPishAzmounFunc=()=>{
    
    let templisttt=JSON.stringify(newMabahes)
    dispatch(R3loading(true))
    if(listSelect.listEnd.length>0){

        axios({
            method:'post',
            url:'https://pejvak-konkur.ir/teacherrest/normallquestion',
            data:{
                tel:allData.allData.Info.tel,
                nawstr:"naw%a_m",
                lesson_id:listSelect.listEnd[0].dars.id,
                list:templisttt,
                
            }
        })
        .then(function (response) {
            if(response.data===101){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"12خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید."))
            }else if(response.data===106){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"شارژ حساب کاربری شما تمام شده است"))
            }else if(response.data===105){
                dispatch(R3loading(false))
                dispatch(R2alert(true,"برای درس انتخابی سوالی موجود نیست"))
            }else{
                dispatch(R3loading(false))
                dispatch(R14setResult(response.data));
                dispatch(R4pages(8,""))
            }
        })
        .catch((error)=>{
            dispatch(R3loading(false))
            dispatch(R2alert(true,"خطای سرور٫ لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید11233."))
        })
    }
    else{
        dispatch(R2alert(true,"شمار درسی انتخاب نکرده اید!!"))
        dispatch(R3loading(false))
    }


}



//=====================================================
// render of component---------------------------------
//=====================================================
    return(
        <div className={style.Container1}>
            {renderSelect()}
            <div className={style.header}>
                <div className={style.right}>
                    <CurentTime/>
                    <UserDetails/>
                </div>
                <div className={style.center}>
                    <DailyText/>
                    <PageKey/>
                </div>
            </div>
            <div className={style.contentContainer}>
                <div className={style.box1content}>
                    <div className={style.selectedDarsBox} >
                        <div className={style.mainLabel}>ساخت آزمون انتخابی</div>
                        <button onClick={openSelectFunc}>اضافه کردن مباحث آزمون</button>
                        <div className={style.selectedCourse}>
                            {renderSelected()}
                        </div>
                    </div>
                    <div className={`${style.buyAccountBtn} animate__animated animate__lightSpeedInRight`} onClick={createPishAzmounFunc}>ایجاد آزمون</div>
                </div>
                <div className={style.box2content}>
                    <img src={img1} alt="teacher pejvok" />
                </div>
            </div>
        </div>
       
    )
}
//=====================================================
//=====================================================
//=====================================================
//component end -------------------------------------
//=====================================================
//=====================================================
//=====================================================